import { useCallback, useRef } from 'react';

import SectionsLayout from 'components/pages/records/layout/SectionsLayout';
import RecordControl from 'components/pages/records/transcript/control/TranscriptControl';
import TranscriptHeader from 'components/pages/records/transcript/header/TranscriptHeader';
import TranscriptList from 'components/views/transcriptionsList/TranscriptList';

import './TranscriptPage.scss';

const TranscriptPage: React.FC = (): JSX.Element => {
    const pageRef = useRef<HTMLDivElement>(null);
    const controlRef = useRef<HTMLDivElement>(null);

    const scrollHandler = useCallback(() => {
        if (!controlRef.current || !pageRef.current) {
            return;
        }

        // 30 - margin-bottom RecordControl в обычном состоянии
        if (pageRef.current.scrollTop - 30 <= 0 && !controlRef.current.offsetTop) {
            controlRef.current?.classList.remove('page_control--onTop');
        } else if (pageRef.current.scrollTop > controlRef.current.offsetTop) {
            controlRef.current?.classList.add('page_control--onTop');
        }
    }, []);

    return (
        <div className='transcript_page' onScroll={scrollHandler} ref={pageRef}>
            <SectionsLayout
                firstChildren={<TranscriptList />}
                secondChildren={<></>}
                control={<RecordControl controlRef={controlRef} />}
                header={<TranscriptHeader />}
            />
        </div>
    );
};

export default TranscriptPage;
