import classNames from 'classnames';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import avatar from 'assets/testAvatar.jpg';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { testAccounts } from 'helpers/TestData';
import { numbersPattern } from 'helpers/ValidationPatterns';

import './AccountantPage.scss';

const AccountantPage: React.FC = () => {
    const [chosenAccount, setChosenAccount] = useState<number>();

    const replenishmentFormMethods = useForm({
        mode: 'onChange',
        defaultValues: {
            amount: '',
        },
    });

    return (
        <div className='accountant_page'>
            <div className='page_title'>Пополнение баланса</div>
            <div className='page_container'>
                <div className='users_list'>
                    {[...testAccounts].map((account, i) => (
                        <div
                            className={classNames('user_card', { 'user_card--active': chosenAccount === i })}
                            onClick={() => setChosenAccount(i)}
                            key={i}
                        >
                            <img src={avatar} alt='avatar' className='avatar' />
                            <p className='username'>{account.username}</p>
                            <div className='empty_block'></div>
                            <p className='money'>{account.amountOfMoney}</p>
                        </div>
                    ))}
                </div>
                <div className='actions'>
                    <FormProvider {...replenishmentFormMethods}>
                        <form
                            onSubmit={replenishmentFormMethods.handleSubmit((cardData) => {})}
                            className='replenishment_amount_form'
                        >
                            <div className='input_wrapper'>
                                <ValidatedInput
                                    required
                                    placeholder='Сумма пополнения'
                                    pattern={numbersPattern}
                                    field='amount'
                                    className='replenishment_amount'
                                    autocompleteOff
                                />
                                {replenishmentFormMethods.formState.errors.amount && (
                                    <ErrorMessage message={replenishmentFormMethods.formState.errors.amount.message} />
                                )}
                            </div>

                            <button type='submit' className='btn btn_primary'>
                                Пополнить
                            </button>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    );
};

export default AccountantPage;
