import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RecordProps } from './RecordItem.interface';

import { useLazyDownloadTranscriptFileQuery } from 'api/routes/ApiApi';
import { useDeleteResultMutation } from 'api/routes/ApiApi';
import { useLazyDownloadFileByIdQuery } from 'api/routes/RecordsApi';
import { ReactComponent as PaperIcon } from 'assets/paper.svg';
import { ReactComponent as SoundIcon } from 'assets/sound.svg';
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg';
import { ModalWithDimming } from 'components/generic/modal/Modal';
import Player from 'components/generic/player/Player';
import { getDateFromTimestamp, getTimeFromTimestamp, msToDefaultTimeFormat } from 'helpers/TimeFormatting';
import { useAppSelector } from 'hooks/useAppSelector';

const RecordItem: React.FC<RecordProps> = ({ id, timestamp, fileId, name, streamUrl, duration, searchedText }) => {
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [downloadRecord, { error: isDownloadRecordError }] = useLazyDownloadFileByIdQuery();
    const [downloadFile, { error: isDownloadFileError }] = useLazyDownloadTranscriptFileQuery();
    const [deleteTranscript] = useDeleteResultMutation();

    const { isFiltered } = useAppSelector((state) => state.archive);
    const { archiveFilterWord } = useAppSelector((state) => state.transcript);

    const renderModalBody = (setModalIsOpen: Dispatch<SetStateAction<boolean>>, id: string) => (
        <React.Fragment>
            <p className='text_light'>Вы действительно хотите удалить запись?</p>
            <p className='text_light'>Отменить это действие будет невозможно.</p>
            <div className='modal_actions'>
                <button type='button' className='cancel_button btn btn_light' onClick={() => setModalIsOpen(false)}>
                    Отменить
                </button>
                <button
                    type='button'
                    className='confirm_button btn btn_danger'
                    onClick={() => {
                        setModalIsOpen(false);
                        deleteTranscript(id);
                    }}
                >
                    Да, удалить
                </button>
            </div>
        </React.Fragment>
    );

    useEffect(() => {
        if (isDownloadRecordError || isDownloadFileError) {
            toast.error('Не удалось скачать файл');
        }
    }, [isDownloadRecordError, isDownloadFileError]);

    return (
        <div className='record_item'>
            <input type='checkbox' className='record_item__input' id={id} />
            <label className='record_item__header' htmlFor={id}>
                <span className='date'>{getDateFromTimestamp(timestamp)}</span>
                <span className='time'>{getTimeFromTimestamp(timestamp)}</span>
                <span className='duration'>{msToDefaultTimeFormat(duration)}</span>
            </label>
            <div onClick={() => navigate(`transcript/${id}`)} className='clickable_area'>
                <div style={{ padding: '20px' }}>
                    <div
                        className='record_item__title'
                        dangerouslySetInnerHTML={
                            isFiltered
                                ? {
                                      __html: name.replaceAll(
                                          new RegExp(archiveFilterWord || '', 'gi'),
                                          `<span class='highlighted'>$&</span>`,
                                      ),
                                  }
                                : undefined
                        }
                    >
                        {!isFiltered ? name : null}
                    </div>
                    {searchedText && (
                        <div className='record_item__text' dangerouslySetInnerHTML={{ __html: searchedText }}></div>
                    )}
                </div>
                <div className='record_item__body'>
                    <div onClick={(e) => e.stopPropagation()}>
                        <Player audioUrl={streamUrl} duration={duration} />
                    </div>
                    <div className='record_actions'>
                        <p className='download_info'>Скачать: </p>
                        <div
                            className='download_text btn btn_secondary'
                            onClick={(e) => {
                                e.stopPropagation();
                                downloadFile({ req_id: id, doc_format: 'docx', name: name });
                            }}
                        >
                            <PaperIcon />
                            <p>Текст</p>
                        </div>
                        <div
                            className='download_record btn btn_secondary'
                            onClick={(e) => {
                                e.stopPropagation();
                                downloadRecord(fileId);
                            }}
                        >
                            <SoundIcon />
                            <p>Запись</p>
                        </div>
                        <DeleteIcon
                            className='delete_record'
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalIsOpen(true);
                            }}
                        />
                        {modalIsOpen && (
                            <ModalWithDimming
                                hideModal={() => setModalIsOpen(false)}
                                modalBody={renderModalBody(setModalIsOpen, id)}
                                modalTitle='Удаление'
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecordItem;
