import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';

import { useLoginMutation, useLazyGetApiTokenQuery } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { inputTypes, LoginCredentials } from 'components/generic/inputFields/InputFields.interfaces';
import { setAPIToken, setJWTToken, setRefreshToken } from 'helpers/LocalStorageActions';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setAuthorized } from 'store/reducers/user/userSlice';

interface LoginFormProps {
    closeMenu?: Function;
}

const LoginForm: React.FC<LoginFormProps> = ({ closeMenu }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [loginUser, { data: tokens, isLoading: loginLoading, isSuccess: loginIsSuccess, isError: loginIsError }] =
        useLoginMutation();
    const [getApiToken, { isLoading: tokenIsLoading }] = useLazyGetApiTokenQuery({});
    const methods = useForm<LoginCredentials>({
        mode: 'onChange',
        defaultValues: {
            username: '',
            password: '',
        },
    });

    useEffect(() => {
        async function saveToken() {
            if (loginIsSuccess && tokens.access && tokens.refresh) {
                setJWTToken(tokens.access);
                setRefreshToken(tokens.refresh);
                let token = await getApiToken(tokens.access).unwrap();
                setAPIToken(token);
                dispatch(setAuthorized(true));
                if (closeMenu) {
                    closeMenu();
                }
                navigate('/archive');
            }
        }
        saveToken();
    }, [loginIsSuccess, closeMenu, dispatch, navigate, getApiToken, tokens]);

    return (
        <div className='login_container'>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit((userData) => {
                        loginUser(userData)
                            .unwrap()
                            .catch((e) => {
                                // todo: add a condition with a check for 401 status
                                setErrorMessage('Неверный логин или пароль');
                            });
                    })}
                    className='section_form'
                >
                    <p className='title'>Авторизация</p>
                    <div className='error_loader_container'>
                        {loginIsError && <ErrorMessage message={errorMessage} />}
                    </div>
                    <div className='input_wrapper'>
                        <ValidatedInput required field='username' placeholder='Логин' />
                        {methods.formState.errors.username && (
                            <ErrorMessage message={methods.formState.errors.username.message} />
                        )}
                    </div>
                    <div className='input_wrapper'>
                        <ValidatedInput
                            required
                            field='password'
                            minLength={5}
                            abilityToHide
                            type={inputTypes.password}
                            placeholder='Пароль'
                        />
                        {methods.formState.errors.password && (
                            <ErrorMessage message={methods.formState.errors.password.message} />
                        )}
                    </div>
                    <button type='submit' className='btn btn_primary' disabled={!methods.formState.isValid}>
                        {loginLoading || tokenIsLoading ? (
                            <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='30' />
                        ) : (
                            'Войти'
                        )}
                    </button>
                    <div>
                        <Link to='/reset-password' className='link'>
                            Забыли пароль?
                        </Link>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default LoginForm;
