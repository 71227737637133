export const CONTENT_PER_PAGE = [1, 2, 3, 5, 10, 20, 50];

export const MILLISECONDS_PER_SECOND = 1000;

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const MONTHS_ABBREVIATIONS = [
    'янв.',
    'фев.',
    'мар.',
    'апр.',
    'май',
    'июн.',
    'июл.',
    'авг.',
    'сен.',
    'окт.',
    'ноя.',
    'дек.',
];

export const WEEKDAY = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const sortProperty = {
    date: 'date',
    name: 'name',
};

export const sortOrder = {
    asc: 'asc',
    desc: 'desc',
};

export const sortingList = [
    { label: 'Дате создания', sortProperty: sortProperty.date, id: 0 },
    { label: 'Названию', sortProperty: sortProperty.name, id: 1 },
];

export const folderActionType = { edit: 'edit', delete: 'delete', create: 'create' };

export const folderActionTypeLabels = {
    [folderActionType.edit]: 'Редактирование',
    [folderActionType.delete]: 'Удаление',
    [folderActionType.create]: 'Создание',
};

export const calendarModes = {
    date: 'date',
    period: 'period',
};

export const allowedTypes = ['audio', 'video'];

export const allowedExtensions = [
    'aac',
    'ac3',
    'avi',
    'bin',
    'codec2',
    'flv',
    'm4v',
    'webm',
    'mlv',
    'mp4',
    'mp3',
    'ogg',
    'oss',
    'vmd',
    'wav',
    'xmv',
    'mkv',
];
