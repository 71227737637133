import { createSlice } from '@reduxjs/toolkit';

import { getTranscriptFontSize, setTranscriptFontSize } from 'helpers/LocalStorageActions';

interface TranscriptAppearanceState {
    fontSize: number;
}

const initialState: TranscriptAppearanceState = {
    fontSize: parseInt(getTranscriptFontSize() || '16'),
};

const slice = createSlice({
    name: 'transcriptAppearance',
    initialState: initialState,
    reducers: {
        increaseFontSize(state) {
            if (state.fontSize < 24) {
                setTranscriptFontSize((state.fontSize + 1).toString());
                state.fontSize++;
            }
        },
        decreaseFontSize(state) {
            if (state.fontSize > 16) {
                setTranscriptFontSize((state.fontSize - 1).toString());
                state.fontSize--;
            }
        },
    },
    extraReducers: (builder) => {},
});

export const { increaseFontSize, decreaseFontSize } = slice.actions;
export default slice.reducer;
