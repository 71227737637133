import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestPayload, TranscriptPayload, SearchTranscriptPayload } from 'api/Models';
import { apiApi } from 'api/routes/ApiApi';

interface ArchiveState {
    records: TranscriptPayload[];
    requests: RequestPayload[];
    filteredRecords: SearchTranscriptPayload[];
    isFiltered: boolean;
}

const slice = createSlice({
    name: 'archive',
    initialState: {
        records: [],
        requests: [],
        filteredRecords: [],
        isFiltered: false,
    } as ArchiveState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(apiApi.endpoints.results.matchFulfilled, (state, { payload }) => {
            state.records = payload.results;
            state.isFiltered = false;
        });
        builder.addMatcher(apiApi.endpoints.requests.matchFulfilled, (state, { payload }) => {
            state.requests = payload.results.filter(
                (item) => item.task_status !== 'success' && item.task_status !== 'revoked',
            );
        });
        builder.addMatcher(apiApi.endpoints.search.matchFulfilled, (state, { payload }) => {
            state.filteredRecords = payload.results;
            state.isFiltered = true;
        });
    },
});

export default slice.reducer;
