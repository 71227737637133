import { useCallback, useEffect, useState } from 'react';

import { RotatingLines } from 'react-loader-spinner';

import { ChooseDictionaryModalProps } from '../Transcript.interface';

import { useGetDictionariesListQuery } from 'api/routes/ApiApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ModalWithDimming } from 'components/generic/modal/Modal';

const ChooseDictionaryModal: React.FC<ChooseDictionaryModalProps> = ({
    setModalIsOpen,
    handleAddToDictionary,
    addedWord,
}) => {
    const {
        data: dictData,
        isLoading: isDictLoading,
        isError: isDictError,
        isSuccess: isDictSuccess,
    } = useGetDictionariesListQuery();

    const [dictId, setDictId] = useState<number | null>(null);
    const [word, setWord] = useState<string>(addedWord || '');

    const [isWordError, setIsWordError] = useState<boolean>(false);
    const [isDictIdError, setIsDictIdError] = useState<boolean>(false);

    const handleClickDictionary = useCallback(() => {
        if (!dictId) {
            setIsDictIdError(true);
            return;
        }
        handleAddToDictionary(dictId, word);
        setModalIsOpen(false);
    }, [dictId, handleAddToDictionary, setModalIsOpen, word]);

    useEffect(() => {
        setIsWordError(!word.length);
    }, [word]);

    return (
        <ModalWithDimming
            hideModal={() => setModalIsOpen(false)}
            modalTitle='Выбрать словарь'
            modalBody={
                <div className='choose_dict_modal'>
                    <div className='input_wrapper'>
                        <input
                            className='input'
                            value={word}
                            onChange={(e) => {
                                setWord(e.target.value);
                            }}
                        ></input>
                        {isWordError && <ErrorMessage message='Введите слово' />}
                    </div>
                    <ul className='dicts_list'>
                        {isDictError && <ErrorMessage message='Не удалось получить список словарей' />}
                        {isDictLoading && (
                            <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='30' />
                        )}
                        {isDictSuccess &&
                            [...dictData.results]
                                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((dict) => (
                                    <li
                                        className={`dicts_list__item ${dict.id === dictId ? 'selected' : ''}`}
                                        key={dict.id}
                                        onClick={() => {
                                            setDictId(dict.id);
                                            setIsDictIdError(false);
                                        }}
                                    >
                                        <span>{dict.name}</span>
                                    </li>
                                ))}
                    </ul>
                    <div className='button_wrapper'>
                        <button
                            className='btn btn_secondary'
                            onClick={handleClickDictionary}
                            disabled={isDictIdError || isWordError}
                        >
                            Добавить
                        </button>
                        {isDictIdError && <ErrorMessage message='Выберите словарь' />}
                    </div>
                </div>
            }
        />
    );
};

export default ChooseDictionaryModal;
