import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';

import { appApi } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { inputTypes, RegisterCredentials } from 'components/generic/inputFields/InputFields.interfaces';
import { emailPattern } from 'helpers/ValidationPatterns';

const RegisterForm: React.FC = () => {
    const navigate = useNavigate();
    const [signUp, { isLoading: registerLoading, isError: registerIsError }] = appApi.useRegisterMutation();
    const [errorMessage, setErrorMessage] = useState('');

    const methods = useForm<RegisterCredentials>({
        mode: 'onChange',
        defaultValues: {
            email: '',
            username: '',
            password: '',
            passwordDuplicate: '',
            first_name: '',
            last_name: '',
        },
    });

    const {
        watch,
        handleSubmit,
        formState: { errors, isValid },
    } = methods;

    return (
        <div className='register_container'>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit((registerInfo) => {
                        signUp(registerInfo)
                            .unwrap()
                            .then((confirmResult) => {
                                if ('error' in confirmResult) {
                                    throw new Error(confirmResult.error);
                                }
                                if ('confirm_url' in confirmResult) {
                                    navigate('/confirm');
                                }
                            })
                            .catch((e) => {
                                setErrorMessage('Логин или почта уже используются');
                            });
                    })}
                    className='section_form'
                >
                    <div className='title'>Регистрация</div>
                    <div className='error_loader_container'>
                        {registerIsError && <ErrorMessage message={errorMessage} />}
                    </div>
                    <div className='input_wrapper'>
                        <ValidatedInput required field='username' placeholder='Логин' />
                        {errors.username && <ErrorMessage message={errors.username.message} />}
                    </div>

                    <div className='input_wrapper'>
                        <ValidatedInput required field='first_name' placeholder='Имя' />
                        {errors.first_name && <ErrorMessage message={errors.first_name.message} />}
                    </div>

                    <div className='input_wrapper'>
                        <ValidatedInput required field='last_name' placeholder='Фамилия' />
                        {errors.last_name && <ErrorMessage message={errors.last_name.message} />}
                    </div>

                    <div className='input_wrapper'>
                        <ValidatedInput
                            required
                            field='email'
                            pattern={emailPattern}
                            placeholder='Email'
                            type={inputTypes.email}
                        />
                        {errors.email && <ErrorMessage message={errors.email.message} />}
                    </div>

                    <div className='input_wrapper'>
                        <ValidatedInput
                            required
                            minLength={5}
                            abilityToHide
                            type={inputTypes.password}
                            field='password'
                            placeholder='Пароль'
                        />
                        {errors.password && <ErrorMessage message={errors.password.message} />}
                    </div>

                    <div className='input_wrapper'>
                        <ValidatedInput
                            required
                            minLength={5}
                            abilityToHide
                            type={inputTypes.password}
                            field='passwordDuplicate'
                            validateValue={watch('password')}
                            placeholder='Повторите пароль'
                        />

                        {errors.passwordDuplicate && <ErrorMessage message={errors.passwordDuplicate.message} />}
                    </div>
                    <button type='submit' className='btn btn_primary' disabled={!isValid}>
                        {registerLoading ? (
                            <RotatingLines strokeColor='white' strokeWidth='5' animationDuration='0.75' width='30' />
                        ) : (
                            'Зарегистрироваться'
                        )}
                    </button>
                    <div>
                        <Link to='/login' className='link'>
                            Уже зарегистрированы? Войти
                        </Link>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default RegisterForm;
