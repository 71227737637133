import { useState, useEffect, useRef, RefObject, useCallback, useMemo } from 'react';

import AudioSpeedDropdown from './AudioSpeedDropdown';
import { PlayerProps } from './Player.interface';

import FormattedTime from 'components/generic/formattedTime/FormattedTime';
import { MILLISECONDS_PER_SECOND } from 'helpers/Constants';

import './Player.scss';

const Player: React.FC<PlayerProps> = ({
    audioUrl,
    audioFragment,
    currentAudioTimestamp,
    audioSpeedChoose,
    duration,
    preload,
}) => {
    const audioRef = useRef() as RefObject<HTMLAudioElement>;

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentAudioTime, setCurrentAudioTime] = useState(audioFragment?.startTime || 0);
    const [audioSpeed, setAudioSpeed] = useState<number>(1);

    useEffect(() => {
        if (currentAudioTimestamp && audioRef.current) {
            audioRef.current.currentTime = currentAudioTimestamp;
        }
    }, [currentAudioTimestamp]);

    useEffect(() => {
        const audio = audioRef.current;

        const action = () => setIsPlaying(false);
        const events = ['ended', 'pause'];

        events.forEach((event) => {
            audio?.addEventListener(event, action);
        });

        return () => {
            events.forEach((event) => {
                audio?.removeEventListener(event, action);
            });
        };
    }, []);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.playbackRate = audioSpeed;
        }
    }, [audioSpeed]);

    useEffect(() => {
        if (audioRef.current && audioFragment?.startTime && audioFragment?.isFragmentPlaying) {
            audioRef.current.currentTime = audioFragment.startTime;
        }
    }, [audioFragment?.startTime, audioFragment?.isFragmentPlaying]);

    useEffect(() => {
        if (audioFragment) {
            setIsPlaying(audioFragment.isFragmentPlaying);
        }
    }, [audioFragment, audioFragment?.isFragmentPlaying]);

    useEffect(() => {
        isPlaying ? audioRef.current?.play() : audioRef.current?.pause();
    }, [isPlaying]);

    const togglePlaying = useCallback(() => {
        setIsPlaying(!isPlaying);
        if (audioRef.current?.ended) {
            audioRef.current?.load();
        }

        if (audioFragment && audioFragment?.isFragmentPlaying) {
            audioFragment.setIsFragmentPlaying(false);
        }
    }, [audioFragment, isPlaying]);

    const onRangeChange = useCallback((e: any) => {
        if (audioRef.current) {
            audioRef.current.currentTime = e.target.value / MILLISECONDS_PER_SECOND;
        }
    }, []);

    const Audio = useMemo(
        () => () =>
            (
                <audio
                    preload={preload ? 'auto' : 'metadata'}
                    ref={audioRef}
                    src={audioUrl}
                    onTimeUpdate={(e) => {
                        setCurrentAudioTime(e.currentTarget.currentTime * MILLISECONDS_PER_SECOND);

                        if (
                            audioFragment?.isFragmentPlaying &&
                            audioFragment?.endTime &&
                            e.currentTarget.currentTime >= audioFragment?.endTime
                        ) {
                            setIsPlaying(false);
                            audioFragment.setIsFragmentPlaying(false);
                        }
                    }}
                >
                    Your browser does not support the audio element.
                </audio>
            ),
        [audioFragment, audioUrl, preload],
    );

    return (
        <div className='player'>
            <div className='play_button_container' onClick={togglePlaying}>
                <div className={isPlaying ? 'play_button--pause' : 'play_button--play'}></div>
            </div>
            {audioSpeedChoose && (
                <AudioSpeedDropdown
                    value={audioSpeed}
                    valueList={audioSpeedChoose}
                    handleChangeValue={(value: number) => {
                        setAudioSpeed(value);
                    }}
                />
            )}
            <Audio />
            <p className='audio_current_time'>
                <FormattedTime duration={currentAudioTime} />
            </p>
            <input
                type='range'
                step={0.001}
                className='audio_range'
                onChange={onRangeChange}
                value={currentAudioTime}
                min={0}
                max={duration - 3 * MILLISECONDS_PER_SECOND}
            />
            <p className='audio_duration'>
                <FormattedTime duration={duration} />
            </p>
        </div>
    );
};

export default Player;
