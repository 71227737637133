import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from './header/RecordsHeader';

import { removeAllFromLS } from 'helpers/LocalStorageActions';
import { useAppSelector } from 'hooks/useAppSelector';

const TemplatePage: React.FC = () => {
    const { isAuthorized } = useAppSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthorized) {
            navigate('/login');
            removeAllFromLS();
        }
    }, [isAuthorized, navigate]);

    return (
        <>
            <Header />
            <Outlet />
        </>
    );
};

export default TemplatePage;
