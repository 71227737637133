import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReplicaPayload, TranscriptPayload } from 'api/Models';
import { apiApi } from 'api/routes/ApiApi';
import {
    ReplicaProps,
    ReplicaWord,
    TranscriptListProps,
} from 'components/views/transcriptionsList/Transcript.interface';
// import { testResultsResponse } from 'helpers/TestData';
import { generateID } from 'helpers/UniqIdGenerator';

interface TranscriptState {
    transcript: TranscriptPayload;
    editedTranscript: TranscriptListProps;
    filteredTranscript: TranscriptListProps;
    speakersList: string[];
    versionChanges: ReplicaProps[][];
    currentVersion: number;
    archiveFilterWord: string | null;
    isFiltered: boolean;
    isEmptyFilterResult: boolean;
    isSaved: boolean;
    isLoading: boolean;
    error: string;
}

const initialStateOfTranscript = {} as TranscriptPayload;
const initialStateOfEditedTranscript = {} as TranscriptListProps;
const initialStateOfFilteredTranscript = {} as TranscriptListProps;

const initState: TranscriptState = {
    transcript: initialStateOfTranscript,
    editedTranscript: initialStateOfEditedTranscript,
    filteredTranscript: initialStateOfFilteredTranscript,
    speakersList: [],
    versionChanges: [],
    currentVersion: 0,
    archiveFilterWord: null,
    isFiltered: false,
    isEmptyFilterResult: false,
    isSaved: true,
    // FIX: сменить на false (?)
    isLoading: true,
    error: '',
};

const slice = createSlice({
    name: 'transcript',
    initialState: initState,
    reducers: {
        setTranscriptName(state, { payload: { name } }: PayloadAction<{ name: string }>) {
            state.editedTranscript.user_path[state.editedTranscript.user_path.length - 1] = name;
        },
        setReplicaWords(
            state,
            {
                payload: { replicaId, words, filteredWords },
            }: PayloadAction<{ replicaId: string; words: ReplicaWord[]; filteredWords: ReplicaWord[] }>,
        ) {
            const replica = state.editedTranscript.result[0].result.find((replica) => replica.id === replicaId);
            if (replica) {
                replica.words = words;
            }
            const filteredReplica = state.filteredTranscript.result[0].result.find(
                (replica) => replica.id === replicaId,
            );
            if (filteredReplica) {
                filteredReplica.words = filteredWords;
            }
        },
        setReplicaSpeaker(
            state,
            { payload: { replicaId, speaker } }: PayloadAction<{ replicaId: string; speaker: string }>,
        ) {
            state.editedTranscript.result[0].result.forEach((replica) => {
                if (replica.id === replicaId) {
                    replica.speaker = speaker;
                    return;
                }
            });

            if (state.speakersList.indexOf(speaker) === -1) {
                state.speakersList.push(speaker);
            }
        },
        createNewReplica(
            state,
            {
                payload: { replicaIdx, replica, filteredWords },
            }: PayloadAction<{ replicaIdx: number; replica: ReplicaProps; filteredWords: ReplicaWord[] }>,
        ) {
            state.editedTranscript.result[0].result.splice(replicaIdx, 0, replica);
            state.filteredTranscript.result[0].result.splice(replicaIdx, 0, { ...replica, words: filteredWords });
        },
        joinReplicas(
            state,
            {
                payload: { mainReplicaIdx, otherReplicaIdx },
            }: PayloadAction<{ mainReplicaIdx: number; otherReplicaIdx: number }>,
        ) {
            const replicas = state.editedTranscript.result[0].result;
            const filteredReplicas = state.filteredTranscript.result[0].result;
            if (replicas[mainReplicaIdx] && replicas[otherReplicaIdx]) {
                replicas[mainReplicaIdx].words.push(
                    ...replicas[otherReplicaIdx].words.filter((word: ReplicaWord) => word[0] !== ''),
                );
                filteredReplicas[mainReplicaIdx].words.push(
                    ...filteredReplicas[otherReplicaIdx].words.filter((word: ReplicaWord) => word[0] !== ''),
                );
                replicas.splice(otherReplicaIdx, 1);
                filteredReplicas.splice(otherReplicaIdx, 1);

                replicas[mainReplicaIdx].needUpdate = true;
            }
        },
        deleteNeedUpdate(state, { payload: { replicaId } }: PayloadAction<{ replicaId: string }>) {
            const replica = state.editedTranscript.result[0].result.find((replica) => replica.id === replicaId);
            if (replica) {
                replica.needUpdate = false;
            }
        },
        addNewVersion(state) {
            if (state.currentVersion < state.versionChanges.length - 1) {
                state.versionChanges.splice(state.currentVersion + 1);
            }

            state.versionChanges.push([...state.editedTranscript.result[0].result]);
            state.currentVersion++;
        },
        setPreviousVersion(state) {
            if (!state.currentVersion) {
                return;
            }

            state.currentVersion--;
            state.editedTranscript.result[0].result = state.versionChanges[state.currentVersion];
            state.filteredTranscript.result[0].result = state.versionChanges[state.currentVersion];

            state.editedTranscript.result[0].result.forEach((rep) => (rep.needUpdate = true));
        },
        setNextVersion(state) {
            if (state.currentVersion === state.versionChanges.length - 1) {
                return;
            }
            state.currentVersion++;
            state.editedTranscript.result[0].result = state.versionChanges[state.currentVersion];
            state.filteredTranscript.result[0].result = state.versionChanges[state.currentVersion];

            state.editedTranscript.result[0].result.forEach((rep) => (rep.needUpdate = true));
        },
        deleteReplica(state, { payload: { replicaIdx } }: PayloadAction<{ replicaIdx: number }>) {
            state.editedTranscript.result[0].result.splice(replicaIdx, 1);
            state.filteredTranscript.result[0].result.splice(replicaIdx, 1);
        },
        setSaved(state, { payload: { isSaved } }: PayloadAction<{ isSaved: boolean }>) {
            state.isSaved = isSaved;
        },
        setArchiveFilterWord(state, { payload: word }: PayloadAction<string | null>) {
            state.archiveFilterWord = word;
        },
        searchWords(state, { payload: text }: PayloadAction<string>) {
            state.isFiltered = true;

            let isWordFounded = false;

            state.filteredTranscript.result[0].result = state.editedTranscript.result[0].result.map((replica) => {
                return {
                    ...replica,
                    words: replica.words.map((word) => {
                        if (word[0].toLowerCase().includes(text.toLowerCase())) {
                            isWordFounded = true;
                            return [
                                word[0].replaceAll(
                                    new RegExp(text, 'gi'),
                                    `<span class='replica__word--searched'>$&</span>`,
                                ),
                                word[1],
                                word[2],
                                [...word[3]],
                            ];
                        } else {
                            return [...word];
                        }
                    }),
                };
            });

            state.isEmptyFilterResult = !isWordFounded;
            state.editedTranscript.result[0].result.forEach((rep) => (rep.needUpdate = true));
        },
        resetSearchWords(state) {
            state.isFiltered = false;

            state.filteredTranscript.result[0].result.forEach((replica) => {
                replica.words.forEach(
                    (word: ReplicaWord) =>
                        (word[0] = word[0]
                            .replaceAll(`<span class='replica__word--searched'>`, '')
                            .replaceAll(`</span>`, '')),
                );
            });

            state.editedTranscript.result[0].result.forEach((rep) => (rep.needUpdate = true));
        },
        reset: () => initState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(apiApi.endpoints.result.matchFulfilled, (state, { payload }) => {
            const transcript = structuredClone(payload);
            transcript.result[0].result.forEach((replica: ReplicaProps) => {
                replica.id = generateID();
                replica.needUpdate = false;
            });

            state.transcript = payload;
            state.editedTranscript = transcript;
            state.filteredTranscript = transcript;

            state.editedTranscript.result[0].result.forEach((replica) =>
                replica.words.forEach((word, i) => {
                    if (i) {
                        word[0] = ` ${word[0]}`;
                    }
                }),
            );

            state.versionChanges.push(state.editedTranscript.result[0].result);

            const speakers = payload.result[0].result.map((replica: ReplicaPayload) => replica.speaker);
            state.speakersList = Array.from(new Set(speakers));
        });
        // заглушка, если с бэком будет что-то не так
        // .addMatcher(apiApi.endpoints.result.matchRejected, (state) => {
        //     const transcript = structuredClone(testResultsResponse);
        //     transcript.result[0].result.forEach((replica: ReplicaProps) => {
        //         replica.id = generateID();
        //         replica.needUpdate = false;
        //     });

        //     state.transcript = testResultsResponse;
        //     state.editedTranscript = transcript;

        //     state.editedTranscript.result[0].result.forEach((replica) =>
        //         replica.words.forEach((word, i) => {
        //             if (i) {
        //                 word[0] = ` ${word[0]}`;
        //             }
        //         }),
        //     );

        //     state.versionChanges.push(state.editedTranscript.result[0].result);

        //     const speakers = testResultsResponse.result[0].result.map((replica: ReplicaPayload) => replica.speaker);
        //     state.speakersList = Array.from(new Set(speakers));
        // });
    },
});

export const {
    setTranscriptName,
    createNewReplica,
    setReplicaWords,
    setReplicaSpeaker,
    joinReplicas,
    addNewVersion,
    setPreviousVersion,
    setNextVersion,
    deleteNeedUpdate,
    deleteReplica,
    setSaved,
    setArchiveFilterWord,
    // setTranscriptFilterWord,
    searchWords,
    resetSearchWords,
    reset,
} = slice.actions;
export default slice.reducer;
