import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { appApi } from 'api/routes/AppApi';
import { checkIfAuthorized } from 'helpers/TokenValidation';

interface UserState {
    id: number;
    isAuthorized: boolean;
    firstName: string;
    lastName: string;
    email: string;
}

const initialState: UserState = {
    id: 0,
    isAuthorized: checkIfAuthorized(),
    firstName: '',
    lastName: '',
    email: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthorized: (state, action: PayloadAction<boolean>) => {
            state.isAuthorized = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(appApi.endpoints.getUserInfo.matchFulfilled, (state, { payload }) => {
            state.firstName = payload.first_name;
            state.lastName = payload.last_name;
            state.id = payload.id;
            state.email = payload.email;
        });
    },
});

export const { setAuthorized } = userSlice.actions;

export default userSlice.reducer;
