import { CheckboxProps } from './Checkbox.interface';

import './Checkbox.scss';

const Checkbox: React.FC<CheckboxProps> = ({
    id,
    checked,
    setChecked,
    text = '',
    disabled = false,
}: CheckboxProps): JSX.Element => {
    return (
        <div className='default_checkbox'>
            <input
                id={id}
                type='checkbox'
                checked={checked}
                className='default_checkbox_input'
                onChange={() => setChecked(!checked)}
                disabled={disabled}
            />
            <label htmlFor={id}>{text}</label>
        </div>
    );
};

export default Checkbox;
