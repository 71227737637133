import { useRef, useState } from 'react';

import ChooseSpeakerModal from './ChooseSpeakerModal';

import { SpeakersDropdownProps } from '../../Transcript.interface';

import { ReactComponent as SpeakerIcon } from 'assets/man.svg';

import { useAppSelector } from 'hooks/useAppSelector';

import useOutsideClick from 'hooks/useOutsideClick';

import './SpeakersDropdown.scss';

const SpeakersDropdown: React.FC<SpeakersDropdownProps> = ({ speaker, handleSpeakerChange }): JSX.Element => {
    const { speakersList } = useAppSelector((state) => state.transcript);

    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isChooseModalOpen, setIsChooseModalOpen] = useState<boolean>(false);

    useOutsideClick([dropdownRef], () => {
        setIsDropdownOpen(false);
    });

    function handleOnClick(item: string) {
        handleSpeakerChange(item);
        setIsDropdownOpen(false);
    }

    return (
        <>
            <div className='speakers_dropdown' ref={dropdownRef}>
                <div
                    tabIndex={0}
                    className='speakers_dropdown__header'
                    role='button'
                    onKeyPress={() => setIsDropdownOpen(!isDropdownOpen)}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                    <SpeakerIcon />
                </div>
                {isDropdownOpen && (
                    <div className='speakers_dropdown__content'>
                        <div className='speakers_dropdown__content__header'>Выбрать спикера</div>
                        <ul className='speakers_dropdown__content__list'>
                            {speakersList.map((item, i) => (
                                <li
                                    className='speakers_dropdown__content__list__item'
                                    key={i}
                                    onClick={() => handleOnClick(item)}
                                >
                                    <span>{item}</span>
                                </li>
                            ))}
                        </ul>
                        <button
                            className='btn btn_secondary'
                            onClick={() => {
                                setIsChooseModalOpen(true);
                                setIsDropdownOpen(false);
                            }}
                        >
                            Выбрать из реестра спикеров
                        </button>
                    </div>
                )}
            </div>
            {isChooseModalOpen && (
                <ChooseSpeakerModal setModalIsOpen={setIsChooseModalOpen} handleSpeakerChange={handleSpeakerChange} />
            )}
        </>
    );
};

export default SpeakersDropdown;
