import { Link, matchPath, useLocation } from 'react-router-dom';

import logo from 'assets/archive/logo.svg';

import './AuthPage.scss';

interface AuthPageProps {
    content: React.ReactNode;
}

const AuthPage: React.FC<AuthPageProps> = ({ content }): JSX.Element => {
    const { pathname } = useLocation();
    const isLoginPath = matchPath('/login', pathname);
    const isResetPasswordPath = matchPath('/reset-password', pathname);
    const isResetPasswordConfirmPath = matchPath('/reset-password/confirm', pathname);
    return (
        <div className='auth_page'>
            <div className='header'>
                <Link to='/' className='header__logo'>
                    <img src={logo} className='header__logo-image' alt='logo' />
                </Link>
                {isLoginPath && (
                    <Link to='/register' className='header__register-button'>
                        <button className='btn btn_secondary'>Регистрация</button>
                    </Link>
                )}
                {isResetPasswordPath && (
                    <Link to='/login' className='header__register-button'>
                        <button className='btn btn_secondary'>Войти</button>
                    </Link>
                )}
                {isResetPasswordConfirmPath && (
                    <Link to='/login' className='header__register-button'>
                        <button className='btn btn_secondary'>Войти</button>
                    </Link>
                )}
            </div>
            {content}
        </div>
    );
};

export default AuthPage;
