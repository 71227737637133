import { Link } from 'react-router-dom';

const SuccessfulConfirmation: React.FC = (): JSX.Element => {
    return (
        <div className='successful_confirmation_container'>
            <p>Аккаунт успешно зарегистрирован.</p>
            <Link to='/login' className='link'>
                <button className='btn btn_secondary'>Войти</button>
            </Link>
        </div>
    );
};

export default SuccessfulConfirmation;
