import { useNavigate, useParams } from 'react-router-dom';

import { useEditResultMutation } from 'api/routes/ApiApi';
import { useGetFilesQuery } from 'api/routes/RecordsApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { audioReset } from 'store/reducers/transcriptions/audioSlice';
import { reset } from 'store/reducers/transcriptions/transcriptSlice';
import { setSaved, setTranscriptName } from 'store/reducers/transcriptions/transcriptSlice';

interface TranscriptHeaderProps {}

const TranscriptHeader: React.FC<TranscriptHeaderProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [sendEditResult] = useEditResultMutation();

    const { transcript, editedTranscript } = useAppSelector((state) => state.transcript);
    const { data: files } = useGetFilesQuery();

    let { transcriptId } = useParams();

    const getRecordName = () => {
        if (transcript && files && 'files' in files) {
            const currentFile = files.files.find((el) => el.file_id === transcript.file_id);
            if (currentFile) {
                return currentFile.path[0];
            }
        }
        return '';
    };

    const handleChangeName = async (e: React.FocusEvent<HTMLDivElement>) => {
        if (e.currentTarget.textContent) {
            dispatch(setSaved({ isSaved: false }));
            dispatch(setTranscriptName({ name: e.currentTarget.textContent }));
            const name = structuredClone(editedTranscript.user_path);
            name[name.length - 1] = e.currentTarget.textContent;
            await sendEditResult({
                req_id: transcriptId || '',
                result: { user_path: name },
            }).unwrap();
            dispatch(setSaved({ isSaved: true }));
        }
    };

    return (
        <div className='transcript_page_header'>
            <div
                className='back_arrow'
                onClick={() => {
                    dispatch(reset());
                    dispatch(audioReset());
                    navigate('/archive');
                }}
            >
                Назад
            </div>
            <div
                className='page_title'
                contentEditable
                suppressContentEditableWarning={true}
                onBlur={(e) => handleChangeName(e)}
                onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        e.target.blur();
                    }
                }}
            >
                {(editedTranscript?.user_path && editedTranscript?.user_path[editedTranscript?.user_path.length - 1]) ||
                    getRecordName()}
            </div>
        </div>
    );
};

export default TranscriptHeader;
