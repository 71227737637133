import { Link } from 'react-router-dom';

import { UploadingProgressProps } from '../UploadingPage.interface';
import './UploadingProgress.scss';

const UploadingProgress: React.FC<UploadingProgressProps> = ({ navigateToFirstStep }) => (
    <div className='decryption-info_container'>
        {/* Закомментировано до появления отображения активных расшифровок на бэке */}
        {/* <div className='progress_wrapper'>
                <p className='progress_percent'>{progress}%</p>
                <ProgressBar progress={progress} animated />
            </div> */}
        <div className='tip'>
            Результат работы вы найдете в разделе{' '}
            <Link to='/archive' className='link'>
                Мои записи
            </Link>
        </div>
        <div className='btn btn_secondary' onClick={navigateToFirstStep}>
            Вернуться в начало
        </div>
    </div>
);

export default UploadingProgress;
