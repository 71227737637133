export const isLargeMobile = window.innerWidth <= 600;
export const isMediumMobile = window.innerWidth <= 485;
export const isDesktop = !isLargeMobile;

export const headerHeight = isLargeMobile ? 70 : 96;

export const LS = {
    API_TOKEN: 'api_token',
    JWT_TOKEN: 'jwt_token',
    REFRESH_TOKEN: 'refresh_token',
    LOGIN: 'login',

    UPLOADING_STEP: 'step',
    ACTIVE_LOAD: 'active_load',
    FILES: 'files',
    FILES_INFO: 'files_info',
    INFO_FORM: 'info_form',

    TRANSCRIPT_FONT_SIZE: 'font_size',
};
