import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { RotatingLines } from 'react-loader-spinner';

import { appApi } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';

import './ProfileReceipts.scss';
import { UserJuridicalInfoSchema } from 'components/generic/inputFields/InputFields.interfaces';

const ProfileReceipts: React.FC = () => {
    const {
        data: juridicalInfo,
        isError: juridicalInfoIsError,
        isSuccess: juridicalInfoIsSuccess,
    } = appApi.useGetJuridicalUserInfoQuery('');
    const [changeJuridicalInfo, { error: updatedJuridicalInfoError, isLoading: updatedJuridicalInfoIsLoading }] =
        appApi.useChangeJuridicalUserInfoMutation();

    const methods = useForm<UserJuridicalInfoSchema>({
        mode: 'onChange',
        defaultValues: {
            LegalName: '',
            TIN: '',
            LegalAddress: '',
        },
    });

    useEffect(() => {
        if (juridicalInfoIsSuccess) {
            methods.setValue('LegalName', juridicalInfo.LegalName);
            methods.setValue('TIN', juridicalInfo.TIN);
            methods.setValue('LegalAddress', juridicalInfo.LegalAddress);
        }
    }, [methods, juridicalInfo, juridicalInfoIsSuccess]);

    return (
        <section className='profile_receipts'>
            <div className='profile_receipts_title'>Мои квитанции</div>
            <div className='profile_receipts_info'>Введите данные юридического лица для формирования квитанции</div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(changeJuridicalInfo)} className='profile_receipts_form'>
                    <div className='error_loader_container'>
                        {updatedJuridicalInfoError && <ErrorMessage message={'Что-то пошло не так'} />}
                    </div>
                    <div className='input_wrapper'>
                        <ValidatedInput required field='LegalName' placeholder='Название' />
                        {methods.formState.errors.LegalName && (
                            <ErrorMessage message={methods.formState.errors.LegalName.message} />
                        )}
                    </div>
                    <div className='input_wrapper'>
                        <ValidatedInput required field='TIN' placeholder='ИНН' />
                        {methods.formState.errors.TIN && (
                            <ErrorMessage message={methods.formState.errors.TIN.message} />
                        )}
                    </div>
                    <div className='input_wrapper'>
                        <ValidatedInput required field='LegalAddress' placeholder='Адрес регистрации' />
                        {methods.formState.errors.LegalAddress && (
                            <ErrorMessage message={methods.formState.errors.LegalAddress.message} />
                        )}
                    </div>
                    {/* <button type='submit' className='btn btn_primary'>
                        Сохранить изменения
                    </button> */}
                    <button
                        type='submit'
                        className='btn btn_primary'
                        disabled={juridicalInfoIsError || !methods.formState.isValid}
                    >
                        {updatedJuridicalInfoIsLoading ? (
                            <RotatingLines strokeColor='grey' strokeWidth='5' animationDuration='0.75' width='30' />
                        ) : (
                            'Сохранить изменения'
                        )}
                    </button>
                </form>
            </FormProvider>
        </section>
    );
};

export default ProfileReceipts;
