export type LoginCredentials = {
    username: string;
    password: string;
    rememberMe?: boolean;
};

export type UsernameSchema = {
    first_name: string;
    last_name: string;
};

export type UserNameAndEmailSchema = {
    first_name: string;
    last_name: string;
};

export type UserJuridicalInfoSchema = {
    TIN: string;
    LegalAddress: string;
    LegalName: string;
};

export type UserInfoSchema = {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_active: boolean;
    date_joined: string;
};

export type RegisterCredentials = {
    username: string;
    email: string;
    password: string;
    passwordDuplicate: string;
    first_name: string;
    last_name: string;
};

export type FeedbackSchema = {
    message: string;
    email: string;
};

export const inputTypes = {
    text: 'text',
    password: 'password',
    email: 'email',
    number: 'number',
};

export interface ValidatedInputProps {
    minLength?: number;
    maxLength?: number;
    min?: number | string;
    max?: number | string;
    field: string;
    type?: string;
    pattern?: RegExp;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    abilityToHide?: boolean;
    validateValue?: string;
    placeholder?: string;
    autofocus?: boolean;
    autocompleteOff?: boolean;
}

export interface ValidatedTextareaProps {
    minLength?: number;
    maxLength?: number;
    field: string;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
}
