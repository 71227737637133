import { Link } from 'react-router-dom';

import RouterTabs from './routerTabs/RouterTabs';

import { appApi } from 'api/routes/AppApi';
import logo from 'assets/archive/logo.svg';
import add from 'assets/balance/add.svg';
import wallet from 'assets/balance/wallet.svg';

import './RecordsHeader.scss';

const SimpleHeader: React.FC = () => {
    const { data: userInfo } = appApi.useGetUserInfoQuery();

    return (
        <header className='records__header'>
            <Link to='/uploading' className='logo_link'>
                <img src={logo} className='logo_image' alt='logo' />
            </Link>
            <div className='empty_block' />
            <RouterTabs />
            <div className='balance'>
                <img src={wallet} className='balance__icon' alt='wallet' />
                <p className='balance__value'>0</p>
                <Link to='/balance/receipt'>
                    <img src={add} className='balance__icon' alt='add' />
                </Link>
            </div>
            <span className='name'>
                {userInfo?.first_name} {userInfo?.last_name}
            </span>
        </header>
    );
};

export default SimpleHeader;
