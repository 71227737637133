import { startOfDay, endOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import Calendar from './calendar/Calendar';

import { useLazySearchQuery, useLazyResultsQuery, useLazyRequestsQuery } from 'api/routes/ApiApi';
import SearchBar from 'components/generic/searchBar/SearchBar';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setArchiveFilterWord } from 'store/reducers/transcriptions/transcriptSlice';

import './SearchFilter.scss';

const SearchFilter: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const [searchDate, setSearchDate] = useState<[string, string] | null>(null);
    const [searchDateRange, setSearchDateRange] = useState<[string, string] | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [getSearchResult] = useLazySearchQuery();
    const [getAllRecords] = useLazyResultsQuery();
    const [getAllRequests] = useLazyRequestsQuery();

    const handleSearchDate = useCallback((date: Date | null) => {
        setSearchDate(date && [startOfDay(date).toISOString(), endOfDay(date).toISOString()]);
    }, []);

    const handleSearchDateRange = useCallback((date: [Date, Date] | null) => {
        setSearchDateRange(date && [startOfDay(date[0]).toISOString(), endOfDay(date[1]).toISOString()]);
    }, []);

    useEffect(() => {
        if (!searchText.length && !searchDate && !searchDateRange) {
            dispatch(setArchiveFilterWord(null));
            getAllRecords();
            getAllRequests();
            return;
        }
        const delayDebounceFn = setTimeout(async () => {
            dispatch(setArchiveFilterWord(searchText));
            await getSearchResult({
                content: searchText,
                date: searchDate ? searchDate : searchDateRange,
            }).unwrap();
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [dispatch, getAllRecords, getAllRequests, getSearchResult, searchDate, searchDateRange, searchText]);

    return (
        <div className='search_filter'>
            <div className='search_bar_wrapper'>
                <SearchBar defaultText='Текст или название' searchText={searchText} setSearchText={setSearchText} />
            </div>
            <Calendar setSearchDate={handleSearchDate} setSearchDateRange={handleSearchDateRange} />
        </div>
    );
};

export default SearchFilter;
