import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ReactComponent as Visa } from 'assets/balance/visa.svg';
import Checkbox from 'components/generic/checkbox/Checkbox';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import Toggle from 'components/generic/toggle/Toggle';

import './TopUpWithCard.scss';
import { testCards } from 'helpers/TestData';

const TopUpWithCard: React.FC = () => {
    const [chosenCardIndex, setChosenCardIndex] = useState<number>(-1);
    const [savingNewCard, setSavingNewCard] = useState<boolean>(false);

    const togglePaymentWithNewCard = () => {
        checkIfPaymentWithNewCard() && testCards.length > 0 ? setChosenCardIndex(0) : setChosenCardIndex(-1);
    };
    const checkIfPaymentWithNewCard = () => chosenCardIndex === -1;

    const cardNumberRef = useRef<HTMLInputElement | null>(null);
    const monthRef = useRef<HTMLInputElement | null>(null);
    const yearRef = useRef<HTMLInputElement | null>(null);
    const cvcRef = useRef<HTMLInputElement | null>(null);
    const refs = [cardNumberRef, monthRef, yearRef, cvcRef];

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            amount: '',
        },
    });

    const autoTab = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            typeof e.target.getAttribute('data-index') === 'undefined' ||
            typeof e.target.getAttribute('maxLength') === 'undefined'
        ) {
            return;
        }

        const tabindex = Number(e.target.getAttribute('data-index'));
        const maxLength = Number(e.target.getAttribute('maxLength'));

        if (
            tabindex < refs.length - 1 &&
            e.target.value.length === maxLength &&
            !isNaN(Number(e.key)) &&
            e.key !== ' '
        ) {
            const nextElem = refs[tabindex + 1];
            if (nextElem && nextElem.current) {
                nextElem.current.focus();
            }
        }
    };

    return (
        <div className='top_up_container'>
            <div className='bank_cards_container'>
                {[...testCards].map((card, i) => (
                    <div
                        key={card.id}
                        className={classNames('bank_card', { 'bank_card--active': chosenCardIndex === i })}
                        onClick={() => setChosenCardIndex(i)}
                    >
                        <Visa />
                        <div className='bank_card__info'>
                            <p className='title'>
                                {card.system} {card.number}
                            </p>
                            <p className='validity_period'>
                                Действует до {card.month}/{card.year}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            <div className='payment_new_card' onClick={togglePaymentWithNewCard}>
                <Checkbox
                    id='payment-new-card'
                    checked={checkIfPaymentWithNewCard()}
                    setChecked={togglePaymentWithNewCard}
                />
                <p>Оплатить другой картой</p>
            </div>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(() => {
                        let numberCard;
                        if (chosenCardIndex === -1) {
                            numberCard = cardNumberRef.current?.value;
                        } else if (chosenCardIndex >= 0) {
                            numberCard = testCards[chosenCardIndex].number;
                        }
                        toast.success('paid with a card ' + numberCard);
                    })}
                >
                    {checkIfPaymentWithNewCard() && (
                        <div className='new_card_container'>
                            <div className='new_card_form input'>
                                <input
                                    type='text'
                                    data-index={0}
                                    pattern='[ 0-9]*'
                                    ref={cardNumberRef}
                                    required={checkIfPaymentWithNewCard()}
                                    maxLength={16}
                                    minLength={16}
                                    onKeyUp={autoTab}
                                    className='input'
                                    placeholder='Номер карты'
                                    inputMode='numeric'
                                />
                                <div className='date'>
                                    <input
                                        ref={monthRef}
                                        required={checkIfPaymentWithNewCard()}
                                        data-index={1}
                                        pattern='[ 0-9]*'
                                        maxLength={2}
                                        minLength={2}
                                        onKeyUp={autoTab}
                                        className='input'
                                        placeholder='ММ'
                                        inputMode='numeric'
                                    />
                                    <span>/</span>
                                    <input
                                        data-index={2}
                                        ref={yearRef}
                                        required={checkIfPaymentWithNewCard()}
                                        pattern='[ 0-9]*'
                                        maxLength={2}
                                        minLength={2}
                                        onKeyUp={autoTab}
                                        className='input'
                                        placeholder='ГГ'
                                        inputMode='numeric'
                                    />
                                </div>
                                <input
                                    data-index={3}
                                    ref={cvcRef}
                                    required={checkIfPaymentWithNewCard()}
                                    pattern='[ 0-9]*'
                                    maxLength={3}
                                    minLength={3}
                                    onKeyUp={autoTab}
                                    className='input cvc'
                                    placeholder='CVC'
                                    inputMode='numeric'
                                />
                            </div>
                            <div className='toggle_container'>
                                <Toggle
                                    id='save_card'
                                    selected={savingNewCard}
                                    setSelected={() => setSavingNewCard(!savingNewCard)}
                                />
                                <p>Сохранить карту</p>
                            </div>
                        </div>
                    )}
                    <div className='deposit_amount_form'>
                        <div className='input_wrapper'>
                            <ValidatedInput
                                placeholder='Сумма пополнения'
                                required
                                field='amount'
                                className='deposit_amount'
                                type='number'
                                min={1}
                                autocompleteOff
                            />
                            {methods.formState.errors.amount && (
                                <ErrorMessage message={methods.formState.errors.amount.message} />
                            )}
                        </div>

                        <button type='submit' className='btn btn_primary' disabled={!methods.formState.isValid}>
                            Пополнить
                        </button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default TopUpWithCard;
