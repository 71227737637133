import { useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import NewDictionaryModal from './NewDictionaryModal';

import { useGetDictionariesListQuery } from 'api/routes/ApiApi';

import { ReactComponent as DictionaryIcon } from 'assets/transcript/dict.svg';
import SearchBar from 'components/generic/searchBar/SearchBar';
import './DictionaryList.scss';

const DictionaryList: React.FC = (): JSX.Element => {
    const {
        data: dictionaries,
        isLoading: isDictionariesLoading,
        error: isDictionariesError,
        isSuccess: isDictionariesSuccess,
    } = useGetDictionariesListQuery();

    const navigate = useNavigate();

    const [searchText, setSearchText] = useState<string>('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <div className='dictionaries_page'>
            <h2 className='page_title'>Словари</h2>
            <div className='page_container'>
                <div className='search_and_add_line'>
                    <SearchBar defaultText='Поиск' searchText={searchText} setSearchText={setSearchText} />
                    <div className='btn btn_white' onClick={() => setModalIsOpen(true)}>
                        <DictionaryIcon />
                        <span>Добавить словарь</span>
                    </div>
                </div>
                <div className='dictionaries_list'>
                    {isDictionariesLoading && (
                        <RotatingLines strokeColor='#814feb' strokeWidth='5' animationDuration='0.75' width='40' />
                    )}
                    {isDictionariesError && (
                        <p style={{ color: 'red' }}>
                            Что-то пошло не так при загрузке списка словарей.
                            <br />
                            Пожалуйста, попробуйте перезагрузить страницу.
                        </p>
                    )}
                    {isDictionariesSuccess && dictionaries?.results.length === 0 && <h2>Нет словарей</h2>}
                    {isDictionariesSuccess &&
                        dictionaries?.results.map((dict, i) => {
                            return (
                                <div key={i} className='dictionary_card' onClick={() => navigate(`/dict/${dict.id}`)}>
                                    <p className='name'>{dict.name}</p>
                                    <p className='count'>{dict.items.length} слов</p>
                                </div>
                            );
                        })}
                </div>
            </div>
            {modalIsOpen && <NewDictionaryModal setModalIsOpen={setModalIsOpen} />}
        </div>
    );
};

export default DictionaryList;
