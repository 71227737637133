import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import SpeakersDropdown from './speakersDropdown/SpeakersDropdown';

import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg';
import { ReplicaWord, TranscriptItemProps } from 'components/views/transcriptionsList/Transcript.interface';
import TranscriptWord from 'components/views/transcriptionsList/transcriptItem/transcriptWord/TranscriptWord';
import { restoreCaretPosition, saveCaretPosition } from 'helpers/ControlCaretPosition';
import { toMinTimeFormat } from 'helpers/TimeFormatting';
import { generateID } from 'helpers/UniqIdGenerator';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useThrottle from 'hooks/useThrottle';

import {
    setPlayingTimestepRange,
    setReplicaPlaying,
    setSelectedPosition,
    setSelectedTimestepRange,
} from 'store/reducers/transcriptions/audioSlice';
import {
    setSelectedReplica,
    setCreateCommentFlag,
    setSelectedWordsText,
} from 'store/reducers/transcriptions/controlSlice';
import {
    addNewVersion,
    createNewReplica,
    deleteNeedUpdate,
    deleteReplica,
    joinReplicas,
    resetSearchWords,
    setNextVersion,
    setPreviousVersion,
    setReplicaSpeaker,
    setReplicaWords,
} from 'store/reducers/transcriptions/transcriptSlice';

import './TranscriptItem.scss';

const isArrayEqual = (a1: ReplicaWord[], a2: ReplicaWord[]): boolean => {
    return a1.length === a2.length && a1.every((item, idx1) => item.every((val, idx2) => val === a2[idx1][idx2]));
};

const TranscriptItem: React.FC<TranscriptItemProps> = ({
    index,
    replica,
    filteredReplica,
    setIsAudioControlDisplay,
    moveToReplica,
}): JSX.Element => {
    const [cloneWords, setCloneWords] = useState<ReplicaWord[]>([...replica.words]);
    const [filteredWords, setFilteredWords] = useState<ReplicaWord[]>([...filteredReplica.words]);

    const [range, setRange] = useState<{ startContainer: Node; endContainer: Node; collapsed: boolean }>();
    const [rangeIndexes, setRangeIndexes] = useState<[number, number]>([0, 0]);

    const nodeStartPosition = useRef<Node | null>();
    const offsetStartPosition = useRef<number>(0);
    const offsetEndPosition = useRef<number>(0);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const replicaRef = useRef<HTMLDivElement>(null);
    const isMounted = useRef<boolean>(false);
    const isUpdated = useRef<boolean>(true);

    const isDeleteClick = useRef<boolean>(false);
    const nextTextNode = useRef<Node | null>();
    const nextTextNodeIndex = useRef<number>(0);

    const dispatch = useAppDispatch();

    const { isReplicaPlaying, playingReplicaId } = useAppSelector((state) => state.audio);
    const { selectedReplicaId, createCommentFlag } = useAppSelector((state) => state.control);
    const { isFiltered } = useAppSelector((state) => state.transcript);

    useEffect(() => {
        if (isMounted.current) {
            if (!isUpdated.current && !isArrayEqual(cloneWords, replica.words)) {
                const delayDebounceFn = setTimeout(() => {
                    dispatch(
                        setReplicaWords({
                            replicaId: replica.id,
                            words: cloneWords,
                            filteredWords: filteredWords,
                        }),
                    );
                    dispatch(addNewVersion());
                }, 500);
                isUpdated.current = false;

                return () => clearTimeout(delayDebounceFn);
            }
        } else {
            isMounted.current = true;
        }
    }, [dispatch, cloneWords, replica.id, replica.words, filteredWords]);

    useEffect(() => {
        if (replica.needUpdate) {
            setCloneWords(replica.words);
            setFilteredWords(filteredReplica.words);
            dispatch(deleteNeedUpdate({ replicaId: replica.id }));
            isUpdated.current = true;
        }
    }, [dispatch, filteredReplica.words, replica, replica.needUpdate]);

    useEffect(() => {
        restoreCaretPosition(nodeStartPosition, offsetStartPosition);
    }, [cloneWords]);

    useEffect(() => {
        if (!isReplicaPlaying || playingReplicaId !== replica.id) {
            setIsPlaying(false);
        }
    }, [isReplicaPlaying, playingReplicaId, replica.id]);

    const handleSpeakerChange = (speaker: string) => {
        dispatch(setReplicaSpeaker({ replicaId: replica.id, speaker: speaker }));
        dispatch(addNewVersion());
    };

    const createReplica = useThrottle((isControl: boolean = false) => {
        const s = rangeIndexes[0];
        const offset = offsetStartPosition.current;

        const e = rangeIndexes[1];
        const endOffset = offsetEndPosition.current;

        const editedReplicaWords = structuredClone(cloneWords).slice(0, s + 1);
        const editedReplicaWordsFiltered = structuredClone(filteredWords).slice(0, s + 1);

        if (editedReplicaWords.length && editedReplicaWords[s].length) {
            editedReplicaWords[s][0] = editedReplicaWordsFiltered[s][0] = editedReplicaWords[s][0].slice(0, offset);
        }

        if (editedReplicaWords.length === 1 && !editedReplicaWords[s][0].length) {
            editedReplicaWords[s][0] = editedReplicaWordsFiltered[s][0] = ' ';
        }

        const newReplicaWords = !isControl
            ? structuredClone(cloneWords).slice(s)
            : structuredClone(cloneWords).slice(s, e + 1);

        const newReplicaWordsFiltered = !isControl
            ? structuredClone(filteredWords).slice(s)
            : structuredClone(filteredWords).slice(s, e + 1);

        if (newReplicaWords.length && newReplicaWords[0].length) {
            newReplicaWords[0][0] = newReplicaWordsFiltered[0][0] = newReplicaWords[0][0].slice(offset);
            if (isControl) {
                newReplicaWords[newReplicaWords.length - 1][0] = newReplicaWordsFiltered[
                    newReplicaWordsFiltered.length - 1
                ][0] =
                    s !== e
                        ? newReplicaWords[newReplicaWords.length - 1][0].slice(0, endOffset)
                        : newReplicaWords[newReplicaWords.length - 1][0].slice(0, endOffset - offset);
            }
        } else {
            const word = structuredClone(cloneWords[s]);
            word[0] = ' ';
            newReplicaWords.push(word);
            newReplicaWordsFiltered.push(word);
        }

        if (newReplicaWords.length === 1 && !newReplicaWords[0][0].length) {
            newReplicaWords[0][0] = newReplicaWordsFiltered[0][0] = ' ';
        }

        let thirdReplicaWords = null;
        let thirdReplicaWordsFiltered = null;
        if (isControl) {
            thirdReplicaWords = structuredClone(cloneWords).slice(e);
            thirdReplicaWordsFiltered = structuredClone(filteredWords).slice(e);

            if (thirdReplicaWords.length && thirdReplicaWords[0].length) {
                thirdReplicaWords[0][0] = thirdReplicaWordsFiltered[0][0] = thirdReplicaWords[0][0].slice(endOffset);
            }

            if (thirdReplicaWords.length === 1 && !thirdReplicaWords[0][0].length) {
                thirdReplicaWords[0][0] = thirdReplicaWordsFiltered[0][0] = ' ';
            }
        }

        setCloneWords(editedReplicaWords);
        setFilteredWords(editedReplicaWordsFiltered);
        isUpdated.current = true;

        dispatch(
            createNewReplica({
                replicaIdx: index + 1,
                replica: {
                    words: newReplicaWords,
                    speaker: replica.speaker,
                    timestep: newReplicaWords[0][3][0],
                    id: generateID(),
                    needUpdate: false,
                },
                filteredWords: newReplicaWordsFiltered,
            }),
        );

        if (isControl) {
            dispatch(
                createNewReplica({
                    replicaIdx: index + 2,
                    replica: {
                        words: thirdReplicaWords,
                        speaker: replica.speaker,
                        timestep: thirdReplicaWords[0][3][0],
                        id: generateID(),
                        needUpdate: false,
                    },
                    filteredWords: thirdReplicaWordsFiltered,
                }),
            );
        }

        dispatch(
            setReplicaWords({
                replicaId: replica.id,
                words: editedReplicaWords,
                filteredWords: editedReplicaWordsFiltered,
            }),
        );
        dispatch(addNewVersion());
    }, 300);

    useEffect(() => {
        if (createCommentFlag && selectedReplicaId === replica.id) {
            createReplica(true);
            dispatch(setCreateCommentFlag(false));
            dispatch(setSelectedReplica(null));
        }
    }, [createReplica, dispatch, createCommentFlag, replica.id, selectedReplicaId]);

    const handleJoinReplica = useCallback(
        (mainReplicaIdx: number, otherReplicaIdx: number) => {
            dispatch(joinReplicas({ mainReplicaIdx, otherReplicaIdx }));
            dispatch(addNewVersion());
        },
        [dispatch],
    );

    const handleEdit = useCallback(() => {
        const C: ReplicaWord[] = structuredClone(cloneWords);
        const filteredC: ReplicaWord[] = structuredClone(filteredWords);

        const start = range?.startContainer;

        saveCaretPosition(nodeStartPosition, offsetStartPosition);

        if (!isDeleteClick.current) {
            C[rangeIndexes[0]][0] = start?.textContent || '';
            filteredC[rangeIndexes[0]][0] = start?.textContent || '';
        } else {
            nextTextNode.current = start?.nextSibling?.firstChild;
            const nextIndex = nextTextNode.current?.parentElement?.getAttribute('data-word');

            if (nextIndex) {
                nextTextNodeIndex.current = parseInt(nextIndex);
            }

            if (nextTextNodeIndex) {
                C[nextTextNodeIndex.current][0] = nextTextNode.current?.nodeValue?.trimStart() || '';
                filteredC[nextTextNodeIndex.current][0] = nextTextNode.current?.nodeValue?.trimStart() || '';
            }

            isDeleteClick.current = false;
        }

        setCloneWords(C);
        setFilteredWords(filteredC);
        isUpdated.current = false;
    }, [cloneWords, filteredWords, range, rangeIndexes]);

    const setAudioInformation = useCallback(() => {
        if (!document.getSelection()?.rangeCount) {
            return;
        }

        const startElement = document
            .getSelection()
            ?.getRangeAt(0)
            .startContainer?.parentElement?.closest('.replica__word');
        const endElement = document
            .getSelection()
            ?.getRangeAt(0)
            .endContainer?.parentElement?.closest('.replica__word');

        if (startElement) {
            const position = startElement.getBoundingClientRect();
            const heightOfControlPanelInPx = 40;
            dispatch(
                setSelectedPosition({
                    leftPosition: position.left,
                    topPosition: position.top - heightOfControlPanelInPx,
                }),
            );
        }

        const startTimestep = startElement?.getAttribute('data-from') || '';
        const endTimestep = endElement?.getAttribute('data-to') || '';

        if (startTimestep && endTimestep) {
            const start = parseFloat(startTimestep);

            if (isReplicaPlaying && playingReplicaId === replica.id) {
                const words = cloneWords.filter((word: ReplicaWord) => word[0] !== '');
                const replicaEnd = words[words.length - 1][3][1];
                dispatch(setPlayingTimestepRange({ startTimestep: start, endTimestep: replicaEnd }));
            }

            const fragmentEnd = parseFloat(endTimestep);
            dispatch(setSelectedTimestepRange({ startTimestep: start, endTimestep: fragmentEnd }));
        }
    }, [cloneWords, dispatch, isReplicaPlaying, playingReplicaId, replica.id]);

    const setControlInformation = useCallback(
        (wordsIdx: [number | null, number | null]) => {
            if (!document.getSelection()?.rangeCount) {
                return;
            }

            dispatch(setSelectedReplica(!document.getSelection()?.getRangeAt(0).collapsed ? replica.id : null));

            if (wordsIdx[0] && wordsIdx[1] && wordsIdx[0] === wordsIdx[1]) {
                let selectedText: string = '';
                for (let i = wordsIdx[0]; i <= wordsIdx[1]; i++) {
                    if (cloneWords[i]) {
                        selectedText += cloneWords[i][0];
                    }
                }
                dispatch(setSelectedWordsText(selectedText));
            } else if (wordsIdx[0] !== wordsIdx[1]) {
                dispatch(setSelectedWordsText(null));
            }
        },
        [dispatch, replica.id, cloneWords],
    );

    const handleSelect = useCallback(() => {
        if (!document.getSelection()?.rangeCount) {
            return;
        }

        const newRange = document.getSelection()?.getRangeAt(0);
        const start = newRange?.startContainer.parentElement?.closest('.replica__word') as Node;
        const end = newRange?.endContainer.parentElement?.closest('.replica__word') as Node;

        saveCaretPosition(nodeStartPosition, offsetStartPosition, 0, offsetEndPosition);

        if (
            newRange?.collapsed &&
            range?.startContainer?.isSameNode(start || null) &&
            range?.endContainer?.isSameNode(end || null)
        ) {
            return;
        }

        if (range?.startContainer) {
            (range?.startContainer as Element)?.classList.remove('replica__word--highlighted');
        }

        if (newRange) {
            setRange({ startContainer: start, endContainer: end, collapsed: newRange.collapsed });
        }

        (start as Element)?.classList.add('replica__word--highlighted');

        const startIndex = (start as Element)?.getAttribute('data-word');
        const endIndex = (end as Element)?.getAttribute('data-word');

        let startI = 0;
        let endI = 0;

        if (startIndex && endIndex) {
            startI = parseInt(startIndex);
            endI = parseInt(endIndex);
        }

        setRangeIndexes([startI, endI]);
        setAudioInformation();
        setControlInformation([startI, endI]);
    }, [range?.endContainer, range?.startContainer, setAudioInformation, setControlInformation]);

    const handleReplicaAudioPlay = useCallback(() => {
        const startTimestep = replica.timestep;
        const endTimestep = cloneWords[cloneWords.length - 1][3][1];

        setIsPlaying(!isPlaying);
        dispatch(setReplicaPlaying({ isPlaying: !isPlaying, replicaId: replica.id }));
        dispatch(setPlayingTimestepRange({ startTimestep, endTimestep }));
    }, [cloneWords, dispatch, isPlaying, replica.id, replica.timestep]);

    const handleDeleteReplica = () => {
        dispatch(deleteReplica({ replicaIdx: index }));
        dispatch(addNewVersion());
    };

    const isSelectionInReplicaBoundaries = useCallback(
        ({ start = true, end = false }) => {
            if (end) {
                saveCaretPosition(nodeStartPosition, offsetStartPosition);

                const lastWordIndex = cloneWords.findLastIndex(
                    (value: ReplicaWord, index: number, obj: ReplicaWord[]) => {
                        return value[0] !== '';
                    },
                );

                return (
                    range?.collapsed &&
                    ((rangeIndexes[1] === lastWordIndex &&
                        offsetStartPosition.current === cloneWords[lastWordIndex][0].length) ||
                        rangeIndexes[1] > lastWordIndex)
                );
            }

            if (start) {
                return (
                    range?.collapsed &&
                    (rangeIndexes[0] === cloneWords.findIndex((word) => word[0] !== '') || cloneWords.length === 1) &&
                    offsetStartPosition.current === 0
                );
            }
        },
        [cloneWords, range?.collapsed, rangeIndexes],
    );

    const insertSubstring = useCallback(
        (str: string, replicaWords: ReplicaWord[], filteredReplicaWords: ReplicaWord[]) => {
            const clone = structuredClone(replicaWords);
            const filteredClone = structuredClone(filteredReplicaWords);

            let word = clone[rangeIndexes[0]][0];
            word = word.substring(0, offsetStartPosition.current) + str + word.substring(offsetStartPosition.current);
            clone[rangeIndexes[0]][0] = word;
            filteredClone[rangeIndexes[0]][0] = word;

            saveCaretPosition(nodeStartPosition, offsetStartPosition, str.length);
            setCloneWords(clone);
            setFilteredWords(filteredClone);
            isUpdated.current = false;
        },
        [rangeIndexes],
    );

    const getSelectedText = useCallback((): string => {
        const startNodeIndex = rangeIndexes[0];
        const startOffset = offsetStartPosition.current;

        const endNodeIndex = rangeIndexes[1];
        const endOffset = document.getSelection()?.getRangeAt(0)?.endOffset;

        let selectedText: string = '';

        selectedText += cloneWords[startNodeIndex][0].slice(startOffset);
        for (let i = startNodeIndex + 1; i < endNodeIndex; i++) {
            selectedText += cloneWords[i][0];
        }
        selectedText += cloneWords[endNodeIndex][0].slice(0, endOffset);

        return selectedText;
    }, [cloneWords, rangeIndexes]);

    const deleteWords = useCallback(
        (str?: string) => {
            const startNodeIndex = rangeIndexes[0];
            const startOffset = offsetStartPosition.current;

            const endNodeIndex = rangeIndexes[1];
            const endOffset = document.getSelection()?.getRangeAt(0)?.endOffset;

            let c: ReplicaWord[] = structuredClone(cloneWords);
            const filteredC: ReplicaWord[] = structuredClone(filteredWords);

            if (range?.collapsed) {
                if (isDeleteClick.current) {
                    c[startNodeIndex + 1][0] = '';
                    filteredC[startNodeIndex + 1][0] = '';
                } else {
                    c[startNodeIndex][0] = '';
                    filteredC[startNodeIndex][0] = '';
                }
            } else if (startNodeIndex === endNodeIndex) {
                c[startNodeIndex][0] =
                    c[startNodeIndex][0].slice(0, startOffset) + c[startNodeIndex][0].slice(endOffset);
                filteredC[startNodeIndex][0] =
                    c[startNodeIndex][0].slice(0, startOffset) + c[startNodeIndex][0].slice(endOffset);
            } else {
                for (let i = startNodeIndex; i <= endNodeIndex; i++) {
                    if (i === startNodeIndex) {
                        c[i][0] = c[i][0].slice(0, startOffset);
                        filteredC[i][0] = c[i][0].slice(0, startOffset);
                    } else if (i === rangeIndexes[1]) {
                        c[i][0] = c[i][0].slice(endOffset);
                        filteredC[i][0] = c[i][0].slice(endOffset);
                    } else {
                        c[i][0] = '';
                        filteredC[i][0] = '';
                    }
                }
            }

            c = c.filter((word: ReplicaWord) => word[0] !== '');

            if (!c.length) {
                const word = structuredClone(cloneWords[0]);
                word[0] = ' ';
                c.push(word);
            }

            if (!isDeleteClick.current) {
                const previousWord = nodeStartPosition.current?.parentNode?.previousSibling;
                nodeStartPosition.current = previousWord?.childNodes[previousWord.childNodes.length - 1];
                offsetStartPosition.current =
                    previousWord?.childNodes[previousWord.childNodes.length - 1].textContent?.length || 0;
            }

            if (str) {
                insertSubstring(str, c, filteredC);
            } else {
                setCloneWords(c);
                setFilteredWords(filteredC);
                isUpdated.current = false;
            }
        },
        [cloneWords, filteredWords, insertSubstring, range?.collapsed, rangeIndexes],
    );

    const handleDeleteWords = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (
                !range?.collapsed &&
                !event.ctrlKey &&
                (event.code.startsWith('Key') ||
                    event.code.startsWith('Digit') ||
                    event.code.startsWith('Numpad') ||
                    event.code === 'Backspace' ||
                    event.code === 'Delete' ||
                    [
                        'Backquote',
                        'Minus',
                        'Equal',
                        'Backslash',
                        'BracketRight',
                        'Backslash',
                        'Semicolon',
                        'Quote',
                        'Comma',
                        'Period',
                        'Slash',
                    ].includes(event.code))
            ) {
                event.preventDefault();
                event.stopPropagation();

                if (event.code === 'Backspace' || event.code === 'Delete') {
                    deleteWords();
                } else {
                    deleteWords(event.key);
                }
            } else if (event.code === 'Backspace' && cloneWords[rangeIndexes[0]][0].length === 1) {
                event.preventDefault();
                event.stopPropagation();

                deleteWords();
            } else if (
                event.code === 'Delete' &&
                isDeleteClick.current &&
                cloneWords[rangeIndexes[0] + 1][0]?.length === 1
            ) {
                event.preventDefault();
                event.stopPropagation();

                deleteWords();
                isDeleteClick.current = false;
            }
        },
        [cloneWords, deleteWords, range?.collapsed, rangeIndexes],
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.code === 'Enter' && event.shiftKey) {
                event.preventDefault();
                event.stopPropagation();

                if (event.repeat) {
                    return;
                }
                insertSubstring('\n', cloneWords, filteredWords);
            } else if (event.code === 'Enter') {
                event.preventDefault();
                event.stopPropagation();

                if (event.repeat) {
                    return;
                }

                createReplica();
            } else if (event.code === 'Backspace' && isSelectionInReplicaBoundaries({})) {
                event.preventDefault();
                event.stopPropagation();

                if (event.repeat) {
                    return;
                }
                moveToReplica(index - 1, true);
                handleJoinReplica(index - 1, index);
            } else if (event.code === 'Delete') {
                if (isSelectionInReplicaBoundaries({ end: true })) {
                    event.preventDefault();
                    event.stopPropagation();

                    if (event.repeat) {
                        return;
                    }
                    handleJoinReplica(index, index + 1);
                } else if (
                    offsetStartPosition.current ===
                    nodeStartPosition.current?.parentElement?.closest('.replica__word')?.textContent?.length
                ) {
                    isDeleteClick.current = true;
                }
            } else if (event.ctrlKey && event.code === 'KeyZ') {
                event.preventDefault();
                event.stopPropagation();
                if (isFiltered) {
                    dispatch(resetSearchWords());
                }
                dispatch(setPreviousVersion());
            } else if (event.ctrlKey && event.code === 'KeyY') {
                event.preventDefault();
                event.stopPropagation();
                if (isFiltered) {
                    dispatch(resetSearchWords());
                }
                dispatch(setNextVersion());
            } else if ((event.code === 'ArrowLeft' || event.code === 'ArrowUp') && isSelectionInReplicaBoundaries({})) {
                event.preventDefault();
                moveToReplica(index - 1, true);
            } else if (
                (event.code === 'ArrowRight' || event.code === 'ArrowDown') &&
                isSelectionInReplicaBoundaries({ end: true })
            ) {
                event.preventDefault();
                moveToReplica(index + 1);
            }

            handleDeleteWords(event);
        },
        [
            isSelectionInReplicaBoundaries,
            handleDeleteWords,
            insertSubstring,
            cloneWords,
            filteredWords,
            createReplica,
            moveToReplica,
            index,
            handleJoinReplica,
            isFiltered,
            dispatch,
        ],
    );

    const words = useMemo(() => {
        if (!isFiltered) {
            return cloneWords.map((word, i) => (
                <TranscriptWord
                    index={[replica.id, i]}
                    text={word[0]}
                    confidence={word[1]}
                    taskMarker={word[2]}
                    timestamp={word[3]}
                    key={i}
                />
            ));
        } else {
            return filteredWords.map((word, i) => (
                <TranscriptWord
                    index={[replica.id, i]}
                    text={word[0]}
                    confidence={word[1]}
                    taskMarker={word[2]}
                    timestamp={word[3]}
                    key={i}
                />
            ));
        }
    }, [cloneWords, filteredWords, isFiltered, replica.id]);

    return (
        <div className='replica'>
            <div className='replica__header'>
                <div className='speaker_name' contentEditable={false}>
                    {`${replica.speaker}:`}
                </div>
                <SpeakersDropdown speaker={replica.speaker} handleSpeakerChange={handleSpeakerChange} />
                <div className='play_button_container' onClick={handleReplicaAudioPlay}>
                    <div className={isPlaying ? 'play_button--pause' : 'play_button--play'}></div>
                </div>
                <span className='audio_duration'>{toMinTimeFormat(replica.timestep)}</span>
                <div className='actions_container'>
                    <div className='tooltip'>
                        <DeleteIcon className='delete-icon' onClick={handleDeleteReplica} />
                        <span className='tooltip_text'>Удалить реплику</span>
                    </div>
                </div>
            </div>
            <div className='replica__content'>
                <div
                    ref={replicaRef}
                    tabIndex={3}
                    className='replica_text'
                    contentEditable
                    suppressContentEditableWarning
                    onSelect={(e) => {
                        e.preventDefault();
                        handleSelect();
                    }}
                    onInput={() => {
                        handleEdit();
                        setIsAudioControlDisplay(false);
                    }}
                    onKeyDown={handleKeyDown}
                    onPaste={(e) => {
                        e.preventDefault();
                        const text = e.clipboardData.getData('text/plain').replace(/\s+/g, ' ').trim();
                        deleteWords(text);
                    }}
                    onCut={(e) => {
                        e.preventDefault();
                        deleteWords();
                        e.clipboardData.setData('text/plain', getSelectedText());
                    }}
                    onBlur={() => {
                        (range?.startContainer as Element)?.classList.remove('replica__word--highlighted');
                        nodeStartPosition.current = undefined;
                        setRange(undefined);
                    }}
                >
                    {words}
                </div>
            </div>
        </div>
    );
};

export default TranscriptItem;
