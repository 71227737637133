import { configureStore } from '@reduxjs/toolkit';

import archive from './reducers/archive/archiveSlice';
import comments from './reducers/comments/commentsSlice';
import file from './reducers/file/fileSlice';
import folders from './reducers/folders/foldersSlice';
import pagination from './reducers/pagination/paginationSlice';
import audio from './reducers/transcriptions/audioSlice';
import control from './reducers/transcriptions/controlSlice';
import transcriptAppearance from './reducers/transcriptions/transcriptAppearanceSlice';
import transcript from './reducers/transcriptions/transcriptSlice';
import user from './reducers/user/userSlice';

import { apiApi } from 'api/routes/ApiApi';
import { appApi } from 'api/routes/AppApi';
import { recordsApi } from 'api/routes/RecordsApi';

const store = configureStore({
    reducer: {
        pagination,
        transcript,
        transcriptAppearance,
        audio,
        control,
        comments,
        archive,
        user,
        folders,
        file,
        [appApi.reducerPath]: appApi.reducer,
        [recordsApi.reducerPath]: recordsApi.reducer,
        [apiApi.reducerPath]: apiApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: false,
        })
            .concat(appApi.middleware)
            .concat(recordsApi.middleware)
            .concat(apiApi.middleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
