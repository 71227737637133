import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReplicaProps, ReplicaWord } from './Transcript.interface';
import TranscriptControlPanel from './transcriptControlPanel/TranscriptControlPanel';
import Replica from './transcriptItem/TranscriptItem';

import { TranscriptResultPayload } from 'api/Models';
import { useEditResultMutation, useLazyResultQuery } from 'api/routes/ApiApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { setSaved } from 'store/reducers/transcriptions/transcriptSlice';

const TranscriptList: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const [getTranscript] = useLazyResultQuery();
    const [sendEditResult] = useEditResultMutation();
    const { transcript, editedTranscript, filteredTranscript, versionChanges } = useAppSelector(
        (state) => state.transcript,
    );

    const [isAudioControlDisplay, setIsAudioControlDisplay] = useState<boolean>(false);
    const transcriptRef = useRef<HTMLDivElement>(null);
    const isMounted = useRef<boolean>(false);

    let { transcriptId } = useParams();

    useEffect(() => {
        if (transcriptId) {
            getTranscript(transcriptId !== undefined ? transcriptId : '');
        }
    }, [getTranscript, transcriptId]);

    const handleSave = useCallback(async () => {
        try {
            const editedResult: ReplicaProps[] = structuredClone(editedTranscript?.result[0].result);
            editedResult.forEach((replica: ReplicaProps) => {
                replica.words.forEach((word: ReplicaWord) => (word[0] = word[0].trim()));

                replica.words.filter((word: ReplicaWord) => word[0] !== '');
            });

            const result = editedResult.map((replica) => ({
                words: replica.words,
                speaker: replica.speaker,
                timestep: replica.timestep,
            }));

            const mainResult: TranscriptResultPayload[] = [
                {
                    result: result,
                    channel: transcript.result[0].channel,
                },
            ];

            await sendEditResult({
                req_id: transcriptId || '',
                result: { result: mainResult },
            }).unwrap();
            dispatch(setSaved({ isSaved: true }));
        } catch (e) {
            console.log(e);
        }
    }, [dispatch, editedTranscript?.result, sendEditResult, transcript.result, transcriptId]);

    useEffect(() => {
        if (isMounted.current) {
            if (versionChanges.length > 1) {
                const delayDebounceFn = setTimeout(() => {
                    dispatch(setSaved({ isSaved: false }));
                    handleSave();
                }, 1000);

                return () => clearTimeout(delayDebounceFn);
            }
        } else {
            isMounted.current = true;
        }
    }, [dispatch, handleSave, versionChanges.length]);

    const moveToReplica = useCallback((replicaIdx: number, toEnd?: boolean) => {
        const s = document.getSelection();
        const range = document.createRange();

        const replica = transcriptRef.current?.getElementsByClassName('replica_text')[replicaIdx];
        const replicaWords = replica?.getElementsByClassName('replica__word');

        if (replicaWords) {
            const replicaText = replicaWords[toEnd ? replicaWords.length - 1 : 0].firstChild;

            if (replicaText && replicaText.nodeValue) {
                range.setStart(replicaText, toEnd ? replicaText.nodeValue.length : 0);
                s?.removeAllRanges();
                s?.addRange(range);
            }
        }
    }, []);

    const replicas = useMemo(() => {
        if (editedTranscript && 'result' in editedTranscript) {
            return editedTranscript?.result[0].result.map((replica: ReplicaProps, i) => (
                <Replica
                    key={replica.id}
                    index={i}
                    replica={replica}
                    filteredReplica={filteredTranscript?.result[0].result[i]}
                    setIsAudioControlDisplay={setIsAudioControlDisplay}
                    moveToReplica={moveToReplica}
                />
            ));
        }
    }, [editedTranscript, filteredTranscript?.result, moveToReplica]);

    return (
        <>
            <div role='textbox' className='transcript_list' ref={transcriptRef}>
                {replicas}
            </div>
            <TranscriptControlPanel
                isAudioControlDisplay={isAudioControlDisplay}
                setIsAudioControlDisplay={setIsAudioControlDisplay}
            />
        </>
    );
};

export default TranscriptList;
