import receipt from 'assets/balance/scanExample.jpg';
import './ReceiptResult.scss';

const ReceiptResult = () => {
    const makeFullScreen = (e: React.MouseEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement;
        if (!document.fullscreenElement) {
            if (target.requestFullscreen) {
                target.requestFullscreen();
            } else {
                console.log('Fullscreen API is not supported');
            }
            return;
        }
        document.exitFullscreen();
    };

    return (
        <>
            <p className='tip'>Вот что у нас получилось. Проверьте, точно ли все в порядке?</p>
            <div className='result_container'>
                <div className='result_image'>
                    <img src={receipt} alt='receipt' onClick={makeFullScreen} />
                </div>
                <div className='result_actions'>
                    <button className='btn btn_primary'>Скачать</button>
                    <button className='btn btn_primary'>Отправить на Email</button>
                </div>
            </div>
        </>
    );
};

export default ReceiptResult;
