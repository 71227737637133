import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';

import { RefreshTokenPayload } from 'api/Models';

import { UserInfoSchema, UserJuridicalInfoSchema } from 'components/generic/inputFields/InputFields.interfaces';
import { setJWTToken, getRefreshToken } from 'helpers/LocalStorageActions';
import { getJWTToken } from 'helpers/LocalStorageActions';
import { logout } from 'helpers/LogoutActions';

const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

const baseQuery = fetchBaseQuery({
    baseUrl: `${REACT_APP_API_HOST}/api/`,
});

type refetchResultSchema = {
    data: { access: string };
};

const baseQueryWithReauth: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    // todo: remove FETCH_ERROR
    if (
        result.error &&
        (result.error.status === 401 || result.error.status === 'FETCH_ERROR') &&
        args.url !== 'login/' &&
        args.url !== 'register/'
    ) {
        const refreshResult = (await baseQuery(
            { url: 'refresh_token/', method: 'POST', body: { refresh: getRefreshToken() } },
            api,
            extraOptions,
        )) as refetchResultSchema;
        if (refreshResult.data) {
            setJWTToken(refreshResult.data.access);
            result = await baseQuery(
                {
                    ...(args as FetchArgs),
                    headers: {
                        Authorization: `Bearer ${refreshResult.data.access}`,
                    },
                },
                api,
                extraOptions,
            );
        } else {
            logout(api.dispatch);
        }
    }
    return result;
};

export const appApi = createApi({
    reducerPath: 'appApi',
    tagTypes: ['UserInfo', 'UserJuridicalInfo'],
    baseQuery: baseQueryWithReauth,

    endpoints: (build) => ({
        login: build.mutation({
            query: (body) => ({
                url: 'login/',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['UserInfo'],
        }),
        getApiToken: build.query({
            query: () => ({
                url: 'api_token/',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            }),
            transformResponse: (response: { token: string }) => response.token,
        }),
        refreshJwtToken: build.mutation<string, { refresh: string }>({
            query: (body) => ({
                url: 'refresh_token/',
                method: 'POST',
                body,
            }),
            transformResponse: (response: RefreshTokenPayload) => response.access,
        }),
        register: build.mutation({
            query: (body) => ({
                url: 'register/',
                method: 'POST',
                body,
            }),
        }),
        resendEmail: build.mutation({
            query: (body) => ({
                url: 'resend_email_token/',
                method: 'POST',
                body,
            }),
        }),
        resetPassword: build.mutation({
            query: (body) => ({
                url: 'password_reset/',
                method: 'POST',
                body,
            }),
        }),
        changePassword: build.mutation({
            query: (body) => ({
                url: 'password_change/',
                method: 'POST',
                body,
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            }),
        }),
        getUserInfo: build.query<UserInfoSchema, void>({
            query: () => ({
                url: 'user_info/',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            }),
            providesTags: ['UserInfo'],
        }),
        changeUserInfo: build.mutation({
            query: (body) => ({
                url: 'user_info/',
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
                body,
            }),
            invalidatesTags: ['UserInfo'],
        }),
        getJuridicalUserInfo: build.query<UserJuridicalInfoSchema, string>({
            query: () => ({
                url: 'juridical_person_info/',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
            }),
            providesTags: ['UserJuridicalInfo'],
        }),
        changeJuridicalUserInfo: build.mutation({
            query: (body) => ({
                url: 'juridical_person_info/',
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
                body,
            }),
            invalidatesTags: ['UserJuridicalInfo'],
        }),
        postFeedback: build.mutation({
            query: (body) => ({
                url: 'feedback/',
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${getJWTToken()}`,
                },
                body,
            }),
        }),
    }),
});

export const {
    useResetPasswordMutation,
    useLazyGetApiTokenQuery,
    useLoginMutation,
    useRegisterMutation,
    useChangePasswordMutation,
} = appApi;
