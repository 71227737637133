export function msToDefaultTimeFormat(duration: number): string {
    const time = msToDefaultTimeFormatDivided(duration);
    return time.join(':');
}

export function msToDefaultTimeFormatDivided(duration: number): string[] {
    const hours = ~~(duration / (1000 * 60 * 60));
    const minutes = ~~((duration / (1000 * 60)) % 60);
    const seconds = ~~((duration / 1000) % 60);

    const ret: string[] = [];

    ret.push(hours.toString().padStart(2, '0'));
    ret.push(minutes.toString().padStart(2, '0'));
    ret.push(seconds.toString().padStart(2, '0'));

    return ret;
}

export function secToDefaultTimeFormat(duration: number): string {
    const time = secToDefaultTimeFormatDivided(duration);
    return time.join(':');
}

export function secToDefaultTimeFormatDivided(duration: number): string[] {
    const hours = ~~(duration / (60 * 60));
    const minutes = ~~((duration / 60) % 60);
    const seconds = ~~(duration % 60);

    const ret: string[] = [];

    ret.push(hours.toString().padStart(2, '0'));
    ret.push(minutes.toString().padStart(2, '0'));
    ret.push(seconds.toString().padStart(2, '0'));

    return ret;
}

export function toMinTimeFormat(duration: number): string {
    const hours = ~~(duration / 3600);
    const minutes = ~~((duration % 3600) / 60);
    const seconds = ~~duration % 60;

    let ret = '';
    if (hours !== 0) {
        ret += `${hours}:`.padStart(3, '0');
    }

    ret += `${minutes}:`.padStart(3, '0') + `${seconds}`.padStart(2, '0');

    return ret;
}

export function getDateFromTimestamp(timestamp: string) {
    const date = new Date(timestamp);
    const day = date.getDate() >= 10 ? `${date.getDate()}` : `0${date.getDate()}`;
    const month = date.getMonth() + 1 >= 10 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`;

    return `${day}.${month}.${date.getFullYear()}`;
}

export function getTimeFromTimestamp(timestamp: string) {
    const date = new Date(timestamp);
    const hours = date.getHours() >= 10 ? `${date.getHours()}` : `0${date.getHours()}`;
    const minutes = date.getMinutes() >= 10 ? `${date.getMinutes()}` : `0${date.getMinutes()}`;

    return `${hours}:${minutes}`;
}
