import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { appApi } from 'api/routes/AppApi';
import { ErrorMessage } from 'components/generic/errorMessage/ErrorMessage';
import { ValidatedInput } from 'components/generic/inputFields/InputFields';
import { inputTypes } from 'components/generic/inputFields/InputFields.interfaces';
import { tinPattern } from 'helpers/ValidationPatterns';

import './ReceiptForm.scss';

const ReceiptForm = () => {
    const navigate = useNavigate();

    const {
        data: juridicalInfo,
        isError: juridicalInfoIsError,
        isSuccess: juridicalInfoIsSuccess,
    } = appApi.useGetJuridicalUserInfoQuery('');

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            LegalName: '',
            TIN: '',
            LegalAddress: '',
            deposit: '',
        },
    });

    useEffect(() => {
        if (juridicalInfoIsSuccess) {
            methods.setValue('LegalName', juridicalInfo.LegalName);
            methods.setValue('TIN', juridicalInfo.TIN);
            methods.setValue('LegalAddress', juridicalInfo.LegalAddress);
        }
    }, [methods, juridicalInfo, juridicalInfoIsSuccess]);

    return (
        <>
            <p className='tip'>
                Введите данные юридического лица и сумму пополнения, и мы сформируем вам счет на оплату аванса.
            </p>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit((userData) => {
                        //actions with receipts would be here
                    })}
                    className='receipt_form'
                >
                    <div className='input_wrapper'>
                        <label className='label'>Название</label>
                        <ValidatedInput required field='LegalName' />
                        {methods.formState.errors.LegalName && (
                            <ErrorMessage message={methods.formState.errors.LegalName.message} />
                        )}
                    </div>
                    <div className='input_wrapper'>
                        <label className='label'>ИНН</label>
                        <ValidatedInput required field='TIN' pattern={tinPattern} />
                        {methods.formState.errors.TIN && (
                            <ErrorMessage message={methods.formState.errors.TIN.message} />
                        )}
                    </div>
                    <div className='input_wrapper'>
                        <label className='label'>Адрес регистрации</label>
                        <ValidatedInput required field='LegalAddress' />
                        {methods.formState.errors.LegalAddress && (
                            <ErrorMessage message={methods.formState.errors.LegalAddress.message} />
                        )}
                    </div>
                    <div className='input_wrapper'>
                        <ValidatedInput
                            required
                            field='deposit'
                            placeholder='Сумма пополнения'
                            className='deposit_amount'
                            type={inputTypes.number}
                        />
                        {methods.formState.errors.deposit && (
                            <ErrorMessage message={methods.formState.errors.deposit.message} />
                        )}
                    </div>
                    <button
                        type='submit'
                        className='btn btn_primary'
                        disabled={juridicalInfoIsError || !methods.formState.isValid}
                        onClick={() => {
                            navigate('result');
                        }}
                    >
                        Сформировать счет
                    </button>
                </form>
            </FormProvider>
        </>
    );
};

export default ReceiptForm;
