import { Outlet } from 'react-router-dom';

import './BalancePage.scss';

const BalancePage = () => (
    <div className='balance_page'>
        <div className='page_title'>Пополнение баланса</div>
        <div className='page_container'>
            <div className='actions'>
                {/* Функционал будет добавлен позднее. Сейчас достуен только раздел "сформировать счет" */}
                {/* <div className='tabs'>
                    <NavLink to='receipt' className={({ isActive }) => (isActive ? 'tab tab--active' : 'tab')}>
                        <Invoice className='tab__icon' />
                        <p className='tab__info'>Сформировать счет</p>
                    </NavLink>
                     <NavLink to='top-up-card' className={({ isActive }) => (isActive ? 'tab tab--active' : 'tab')}>
                        <Card className='tab__icon' />
                        <p className='tab__info'>Пополнить с карты</p>
                    </NavLink>
                    <NavLink to='top-up-account' className={({ isActive }) => (isActive ? 'tab tab--active' : 'tab')}>
                        <Bank className='tab__icon' />
                        <p className='tab__info'>Пополнить со счета</p>
                    </NavLink>
                </div> */}
                <Outlet />
            </div>
            <div className='page_info'>
                <p className='page_info__title'>
                    В течение 3 дней после окончания календарного месяца мы пришлем вам на почту закрывающие документы
                </p>
                <p className='page_info__text'>
                    Если вам нужны оригиналы счета и закрывающих документов, пожалуйста, обратитесь в поддержку. По
                    вашему требованию мы можем прислать документы почтой или курьерской доставкой.
                </p>
            </div>
        </div>
    </div>
);

export default BalancePage;
