import { DictionariesPayload, TranscriptPayload } from 'api/Models';
import { CommentProps } from 'components/views/commentsList/commentItem/CommentItem.interfaces';

export interface FolderModel {
    id: number;
    name: string;
    root?: boolean;
    parent: number | null;
}

export const testFolders = [
    {
        id: 0,
        name: 'Совещания май 2021',
        root: true,
        parent: null,
    },
    {
        id: 1,
        name: 'Планерки',
        root: false,
        parent: 0,
    },
    {
        id: 2,
        name: 'Неразобранное',
        root: false,
        parent: 0,
    },
    {
        id: 3,
        name: 'Разное',
        root: false,
        parent: 1,
    },
] as FolderModel[];

export const testResultsResponse = {
    id: 1,
    timestamp: '2022-11-28T08:51:26.783087Z',
    result: [
        {
            result: [
                {
                    words: [
                        ['Добрый', 0.0, 0, [3.28, 3.36]],
                        ['день,', 0.0, 0, [3.6, 3.68]],
                        ['коллеги.', 0.0, 0, [3.84, 4.16]],
                        ['Как', 0.0, 0, [4.52, 4.6]],
                        ['прошли', 0.0, 0, [4.76, 5.04]],
                        ['выходные?', 0.0, 0, [5.12, 5.52]],
                        ['Надеюсь,', 0.0, 0, [6.0, 6.36]],
                        ['что', 0.0, 0, [6.44, 6.52]],
                        ['все', 0.0, 0, [6.84, 6.92]],
                        ['хорошо', 0.0, 0, [7.08, 7.16]],
                        ['отдохнули', 2.0, 0, [7.4, 8.0]],
                        ['и', 0.0, 0, [8.36, 8.44]],
                        ['готовы', 1.0, 0, [8.72, 9.12]],
                        ['активно', 0.0, 0, [9.28, 9.68]],
                        ['включиться', 0.0, 0, [9.84, 10.28]],
                        ['в', 0.0, 0, [10.36, 10.44]],
                        ['работу.', 0.0, 0, [10.48, 10.8]],
                        ['Начнем', 0.0, 0, [11.16, 11.52]],
                        ['совещание', 0.0, 0, [11.6, 12.08]],
                        ['по', 0.0, 0, [12.76, 12.84]],
                        ['первому', 0.0, 0, [12.92, 13.24]],
                        ['вопросу', 0.0, 0, [13.28, 13.72]],
                        ['подготовки', 0.0, 0, [13.96, 14.56]],
                        ['говорной', 0.0, 0, [15.04, 15.52]],
                        ['документации', 0.0, 0, [15.8, 16.4]],
                        ['на', 0.0, 0, [16.6, 16.68]],
                        ['строительство.', 0.0, 0, [16.76, 17.36]],
                        ['Хотелось', 0.0, 0, [18.08, 18.4]],
                        ['бы', 0.0, 0, [18.48, 18.56]],
                        ['послушать', 0.0, 0, [18.64, 19.16]],
                        ['руководителя', 0.0, 0, [19.44, 20.0]],
                        ['планово', 2.0, 0, [20.36, 20.72]],
                        ['экономического', 0.0, 0, [20.92, 21.32]],
                        ['отдела', 0.0, 0, [21.56, 21.88]],
                        ['Петрова', 0.0, 0, [22.12, 23.32]],
                        ['Владимира', 0.0, 0, [22.84, 23.32]],
                        ['Ивановича.', 0.0, 0, [23.4, 23.96]],
                    ],
                    speaker: 'SPEAKER_00',
                    timestep: 3.28,
                },
                {
                    words: [
                        ['Добрый', 0.0, 0, [3.28, 3.36]],
                        ['день,', 0.0, 0, [3.6, 3.68]],
                        ['коллеги.', 0.0, 0, [3.84, 4.16]],
                        ['Как', 0.0, 0, [4.52, 4.6]],
                        ['прошли', 0.0, 0, [4.76, 5.04]],
                        ['выходные?', 0.0, 0, [5.12, 5.52]],
                        ['Надеюсь,', 0.0, 0, [6.0, 6.36]],
                        ['что', 0.0, 0, [6.44, 6.52]],
                        ['все', 0.0, 0, [6.84, 6.92]],
                        ['хорошо', 0.0, 0, [7.08, 7.16]],
                        ['отдохнули', 2.0, 0, [7.4, 8.0]],
                        ['и', 0.0, 0, [8.36, 8.44]],
                        ['готовы', 1.0, 0, [8.72, 9.12]],
                        ['активно', 0.0, 0, [9.28, 9.68]],
                        ['включиться', 0.0, 0, [9.84, 10.28]],
                        ['в', 0.0, 0, [10.36, 10.44]],
                        ['работу.', 0.0, 0, [10.48, 10.8]],
                        ['Начнем', 0.0, 0, [11.16, 11.52]],
                        ['совещание', 0.0, 0, [11.6, 12.08]],
                        ['по', 0.0, 0, [12.76, 12.84]],
                        ['первому', 0.0, 0, [12.92, 13.24]],
                        ['вопросу', 0.0, 0, [13.28, 13.72]],
                        ['подготовки', 0.0, 0, [13.96, 14.56]],
                        ['говорной', 0.0, 0, [15.04, 15.52]],
                        ['документации', 0.0, 0, [15.8, 16.4]],
                        ['на', 0.0, 0, [16.6, 16.68]],
                        ['строительство.', 0.0, 0, [16.76, 17.36]],
                        ['Хотелось', 0.0, 0, [18.08, 18.4]],
                        ['бы', 0.0, 0, [18.48, 18.56]],
                        ['послушать', 0.0, 0, [18.64, 19.16]],
                        ['руководителя', 0.0, 0, [19.44, 20.0]],
                        ['планово', 2.0, 0, [20.36, 20.72]],
                        ['экономического', 0.0, 0, [20.92, 21.32]],
                        ['отдела', 0.0, 0, [21.56, 21.88]],
                        ['Петрова', 0.0, 0, [22.12, 23.32]],
                        ['Владимира', 0.0, 0, [22.84, 23.32]],
                        ['Ивановича.', 0.0, 0, [23.4, 23.96]],
                    ],
                    speaker: 'SPEAKER_00',
                    timestep: 3.28,
                },
                {
                    words: [
                        ['Добрый', 0.0, 0, [3.28, 3.36]],
                        ['день,', 0.0, 0, [3.6, 3.68]],
                        ['коллеги.', 0.0, 0, [3.84, 4.16]],
                        ['Как', 0.0, 0, [4.52, 4.6]],
                        ['прошли', 0.0, 0, [4.76, 5.04]],
                        ['выходные?', 0.0, 0, [5.12, 5.52]],
                        ['Надеюсь,', 0.0, 0, [6.0, 6.36]],
                        ['что', 0.0, 0, [6.44, 6.52]],
                        ['все', 0.0, 0, [6.84, 6.92]],
                        ['хорошо', 0.0, 0, [7.08, 7.16]],
                        ['отдохнули', 2.0, 0, [7.4, 8.0]],
                        ['и', 0.0, 0, [8.36, 8.44]],
                        ['готовы', 1.0, 0, [8.72, 9.12]],
                        ['активно', 0.0, 0, [9.28, 9.68]],
                        ['включиться', 0.0, 0, [9.84, 10.28]],
                        ['в', 0.0, 0, [10.36, 10.44]],
                        ['работу.', 0.0, 0, [10.48, 10.8]],
                        ['Начнем', 0.0, 0, [11.16, 11.52]],
                        ['совещание', 0.0, 0, [11.6, 12.08]],
                        ['по', 0.0, 0, [12.76, 12.84]],
                        ['первому', 0.0, 0, [12.92, 13.24]],
                        ['вопросу', 0.0, 0, [13.28, 13.72]],
                        ['подготовки', 0.0, 0, [13.96, 14.56]],
                        ['говорной', 0.0, 0, [15.04, 15.52]],
                        ['документации', 0.0, 0, [15.8, 16.4]],
                        ['на', 0.0, 0, [16.6, 16.68]],
                        ['строительство.', 0.0, 0, [16.76, 17.36]],
                        ['Хотелось', 0.0, 0, [18.08, 18.4]],
                        ['бы', 0.0, 0, [18.48, 18.56]],
                        ['послушать', 0.0, 0, [18.64, 19.16]],
                        ['руководителя', 0.0, 0, [19.44, 20.0]],
                        ['планово', 2.0, 0, [20.36, 20.72]],
                        ['экономического', 0.0, 0, [20.92, 21.32]],
                        ['отдела', 0.0, 0, [21.56, 21.88]],
                        ['Петрова', 0.0, 0, [22.12, 23.32]],
                        ['Владимира', 0.0, 0, [22.84, 23.32]],
                        ['Ивановича.', 0.0, 0, [23.4, 23.96]],
                    ],
                    speaker: 'SPEAKER_00',
                    timestep: 3.28,
                },
                {
                    words: [
                        ['Добрый', 0.0, 0, [3.28, 3.36]],
                        ['день,', 0.0, 0, [3.6, 3.68]],
                        ['коллеги.', 0.0, 0, [3.84, 4.16]],
                        ['Как', 0.0, 0, [4.52, 4.6]],
                        ['прошли', 0.0, 0, [4.76, 5.04]],
                        ['выходные?', 0.0, 0, [5.12, 5.52]],
                        ['Надеюсь,', 0.0, 0, [6.0, 6.36]],
                        ['что', 0.0, 0, [6.44, 6.52]],
                        ['все', 0.0, 0, [6.84, 6.92]],
                        ['хорошо', 0.0, 0, [7.08, 7.16]],
                        ['отдохнули', 2.0, 0, [7.4, 8.0]],
                        ['и', 0.0, 0, [8.36, 8.44]],
                        ['готовы', 1.0, 0, [8.72, 9.12]],
                        ['активно', 0.0, 0, [9.28, 9.68]],
                        ['включиться', 0.0, 0, [9.84, 10.28]],
                        ['в', 0.0, 0, [10.36, 10.44]],
                        ['работу.', 0.0, 0, [10.48, 10.8]],
                        ['Начнем', 0.0, 0, [11.16, 11.52]],
                        ['совещание', 0.0, 0, [11.6, 12.08]],
                        ['по', 0.0, 0, [12.76, 12.84]],
                        ['первому', 0.0, 0, [12.92, 13.24]],
                        ['вопросу', 0.0, 0, [13.28, 13.72]],
                        ['подготовки', 0.0, 0, [13.96, 14.56]],
                        ['говорной', 0.0, 0, [15.04, 15.52]],
                        ['документации', 0.0, 0, [15.8, 16.4]],
                        ['на', 0.0, 0, [16.6, 16.68]],
                        ['строительство.', 0.0, 0, [16.76, 17.36]],
                        ['Хотелось', 0.0, 0, [18.08, 18.4]],
                        ['бы', 0.0, 0, [18.48, 18.56]],
                        ['послушать', 0.0, 0, [18.64, 19.16]],
                        ['руководителя', 0.0, 0, [19.44, 20.0]],
                        ['планово', 2.0, 0, [20.36, 20.72]],
                        ['экономического', 0.0, 0, [20.92, 21.32]],
                        ['отдела', 0.0, 0, [21.56, 21.88]],
                        ['Петрова', 0.0, 0, [22.12, 23.32]],
                        ['Владимира', 0.0, 0, [22.84, 23.32]],
                        ['Ивановича.', 0.0, 0, [23.4, 23.96]],
                    ],
                    speaker: 'SPEAKER_00',
                    timestep: 3.28,
                },
                {
                    words: [
                        ['Добрый', 0.0, 0, [3.28, 3.36]],
                        ['день,', 0.0, 0, [3.6, 3.68]],
                        ['коллеги.', 0.0, 0, [3.84, 4.16]],
                        ['Как', 0.0, 0, [4.52, 4.6]],
                        ['прошли', 0.0, 0, [4.76, 5.04]],
                        ['выходные?', 0.0, 0, [5.12, 5.52]],
                        ['Надеюсь,', 0.0, 0, [6.0, 6.36]],
                        ['что', 0.0, 0, [6.44, 6.52]],
                        ['все', 0.0, 0, [6.84, 6.92]],
                        ['хорошо', 0.0, 0, [7.08, 7.16]],
                        ['отдохнули', 2.0, 0, [7.4, 8.0]],
                        ['и', 0.0, 0, [8.36, 8.44]],
                        ['готовы', 1.0, 0, [8.72, 9.12]],
                        ['активно', 0.0, 0, [9.28, 9.68]],
                        ['включиться', 0.0, 0, [9.84, 10.28]],
                        ['в', 0.0, 0, [10.36, 10.44]],
                        ['работу.', 0.0, 0, [10.48, 10.8]],
                        ['Начнем', 0.0, 0, [11.16, 11.52]],
                        ['совещание', 0.0, 0, [11.6, 12.08]],
                        ['по', 0.0, 0, [12.76, 12.84]],
                        ['первому', 0.0, 0, [12.92, 13.24]],
                        ['вопросу', 0.0, 0, [13.28, 13.72]],
                        ['подготовки', 0.0, 0, [13.96, 14.56]],
                        ['говорной', 0.0, 0, [15.04, 15.52]],
                        ['документации', 0.0, 0, [15.8, 16.4]],
                        ['на', 0.0, 0, [16.6, 16.68]],
                        ['строительство.', 0.0, 0, [16.76, 17.36]],
                        ['Хотелось', 0.0, 0, [18.08, 18.4]],
                        ['бы', 0.0, 0, [18.48, 18.56]],
                        ['послушать', 0.0, 0, [18.64, 19.16]],
                        ['руководителя', 0.0, 0, [19.44, 20.0]],
                        ['планово', 2.0, 0, [20.36, 20.72]],
                        ['экономического', 0.0, 0, [20.92, 21.32]],
                        ['отдела', 0.0, 0, [21.56, 21.88]],
                        ['Петрова', 0.0, 0, [22.12, 23.32]],
                        ['Владимира', 0.0, 0, [22.84, 23.32]],
                        ['Ивановича.', 0.0, 0, [23.4, 23.96]],
                    ],
                    speaker: 'SPEAKER_00',
                    timestep: 3.28,
                },
                {
                    words: [
                        ['Добрый', 0.0, 0, [3.28, 3.36]],
                        ['день,', 0.0, 0, [3.6, 3.68]],
                        ['коллеги.', 0.0, 0, [3.84, 4.16]],
                        ['Как', 0.0, 0, [4.52, 4.6]],
                        ['прошли', 0.0, 0, [4.76, 5.04]],
                        ['выходные?', 0.0, 0, [5.12, 5.52]],
                        ['Надеюсь,', 0.0, 0, [6.0, 6.36]],
                        ['что', 0.0, 0, [6.44, 6.52]],
                        ['все', 0.0, 0, [6.84, 6.92]],
                        ['хорошо', 0.0, 0, [7.08, 7.16]],
                        ['отдохнули', 2.0, 0, [7.4, 8.0]],
                        ['и', 0.0, 0, [8.36, 8.44]],
                        ['готовы', 1.0, 0, [8.72, 9.12]],
                        ['активно', 0.0, 0, [9.28, 9.68]],
                        ['включиться', 0.0, 0, [9.84, 10.28]],
                        ['в', 0.0, 0, [10.36, 10.44]],
                        ['работу.', 0.0, 0, [10.48, 10.8]],
                        ['Начнем', 0.0, 0, [11.16, 11.52]],
                        ['совещание', 0.0, 0, [11.6, 12.08]],
                        ['по', 0.0, 0, [12.76, 12.84]],
                        ['первому', 0.0, 0, [12.92, 13.24]],
                        ['вопросу', 0.0, 0, [13.28, 13.72]],
                        ['подготовки', 0.0, 0, [13.96, 14.56]],
                        ['говорной', 0.0, 0, [15.04, 15.52]],
                        ['документации', 0.0, 0, [15.8, 16.4]],
                        ['на', 0.0, 0, [16.6, 16.68]],
                        ['строительство.', 0.0, 0, [16.76, 17.36]],
                        ['Хотелось', 0.0, 0, [18.08, 18.4]],
                        ['бы', 0.0, 0, [18.48, 18.56]],
                        ['послушать', 0.0, 0, [18.64, 19.16]],
                        ['руководителя', 0.0, 0, [19.44, 20.0]],
                        ['планово', 2.0, 0, [20.36, 20.72]],
                        ['экономического', 0.0, 0, [20.92, 21.32]],
                        ['отдела', 0.0, 0, [21.56, 21.88]],
                        ['Петрова', 0.0, 0, [22.12, 23.32]],
                        ['Владимира', 0.0, 0, [22.84, 23.32]],
                        ['Ивановича.', 0.0, 0, [23.4, 23.96]],
                    ],
                    speaker: 'SPEAKER_00',
                    timestep: 3.28,
                },
                {
                    words: [
                        ['Добрый', 0.0, 0, [3.28, 3.36]],
                        ['день,', 0.0, 0, [3.6, 3.68]],
                        ['коллеги.', 0.0, 0, [3.84, 4.16]],
                        ['Как', 0.0, 0, [4.52, 4.6]],
                        ['прошли', 0.0, 0, [4.76, 5.04]],
                        ['выходные?', 0.0, 0, [5.12, 5.52]],
                        ['Надеюсь,', 0.0, 0, [6.0, 6.36]],
                        ['что', 0.0, 0, [6.44, 6.52]],
                        ['все', 0.0, 0, [6.84, 6.92]],
                        ['хорошо', 0.0, 0, [7.08, 7.16]],
                        ['отдохнули', 2.0, 0, [7.4, 8.0]],
                        ['и', 0.0, 0, [8.36, 8.44]],
                        ['готовы', 1.0, 0, [8.72, 9.12]],
                        ['активно', 0.0, 0, [9.28, 9.68]],
                        ['включиться', 0.0, 0, [9.84, 10.28]],
                        ['в', 0.0, 0, [10.36, 10.44]],
                        ['работу.', 0.0, 0, [10.48, 10.8]],
                        ['Начнем', 0.0, 0, [11.16, 11.52]],
                        ['совещание', 0.0, 0, [11.6, 12.08]],
                        ['по', 0.0, 0, [12.76, 12.84]],
                        ['первому', 0.0, 0, [12.92, 13.24]],
                        ['вопросу', 0.0, 0, [13.28, 13.72]],
                        ['подготовки', 0.0, 0, [13.96, 14.56]],
                        ['говорной', 0.0, 0, [15.04, 15.52]],
                        ['документации', 0.0, 0, [15.8, 16.4]],
                        ['на', 0.0, 0, [16.6, 16.68]],
                        ['строительство.', 0.0, 0, [16.76, 17.36]],
                        ['Хотелось', 0.0, 0, [18.08, 18.4]],
                        ['бы', 0.0, 0, [18.48, 18.56]],
                        ['послушать', 0.0, 0, [18.64, 19.16]],
                        ['руководителя', 0.0, 0, [19.44, 20.0]],
                        ['планово', 2.0, 0, [20.36, 20.72]],
                        ['экономического', 0.0, 0, [20.92, 21.32]],
                        ['отдела', 0.0, 0, [21.56, 21.88]],
                        ['Петрова', 0.0, 0, [22.12, 23.32]],
                        ['Владимира', 0.0, 0, [22.84, 23.32]],
                        ['Ивановича.', 0.0, 0, [23.4, 23.96]],
                    ],
                    speaker: 'SPEAKER_00',
                    timestep: 3.28,
                },
            ],
            channel: 0,
        },
    ],
    version: 1,
    session_id: '66e8693c-8e3e-413f-919e-8f725c2a0a06',
    session_timestamp: '2022-11-28 08:46:08.819203+00:00',
    file_id: 'ec03683c-17a2-4af6-af39-7ed7d7a95e17',
    user_path: ['Какое-нибудь достаточно длинное название'],
    duration: 24464,
} as TranscriptPayload;

export const testUsers = [
    { id: -1, lastName: 'Постнов' },
    { id: -2, lastName: 'Константинопольский' },
    { id: -3, lastName: 'Исаева' },
];

export const testComments = [
    {
        id: 1,
        timestamp: '2022-08-24T11:40:50.489535Z',
        position_start: 11.24,
        position_end: 15.82,
        user: -1,
        text: 'Задача организации, в особенности же новая модель организационной деятельности',
    },
    {
        id: 2,
        timestamp: '2022-08-15T06:37:09.727Z',
        position_start: 11.24,
        position_end: 15.82,
        user: -2,
        text: 'Идейные соображения высшего порядка, а также высокое качество позиционных исследований не даёт нам иного выбора.',
    },
    {
        id: 3,
        timestamp: '2010-04-05T14:20:10.310Z',
        position_start: 11.24,
        position_end: 15.82,
        user: -3,
        text: 'Представители современных социальных резервов набирают популярность среди определенных слоев населения, а значит, должны быть представлены в исключительно положительном свете.',
    },
] as CommentProps[];

export const testCards = [
    {
        id: 0,
        logoPath: 'assets/balance/visa.svg',
        month: '06',
        year: '24',
        cvc: '334',
        number: 'xxxx 1234',
        system: 'Visa',
    },
    {
        id: 1,
        logoPath: 'assets/balance/mastercard.svg',
        month: '10',
        year: '23',
        cvc: '714',
        number: 'xxxx 1234',
        system: 'MasterCard',
    },
];

export const testAccounts = [
    { username: 'Ангелина Сорокина', photoPath: '', amountOfMoney: 13000 },
    { username: 'Ангелина Сорокина', photoPath: '', amountOfMoney: 13000 },
    { username: 'Ангелина Сорокина', photoPath: '', amountOfMoney: 13000 },
    { username: 'Ангелина Сорокина', photoPath: '', amountOfMoney: 13000 },
];

export const testDictionariesList = {
    count: 3,
    next: null,
    previous: null,
    results: [
        {
            id: 0,
            timestamp: '2023-01-26T06:01:30.124Z',
            flags: 2147483647,
            name: 'My first dictionary',
            items: [
                {
                    id: 0,
                    timestamp: '2023-01-26T06:01:30.124Z',
                    dict: 0,
                    word: 'Word',
                },
                {
                    id: 1,
                    timestamp: '2023-01-26T06:01:30.124Z',
                    dict: 0,
                    word: 'smth',
                },
                {
                    id: 2,
                    timestamp: '2023-01-26T06:01:30.124Z',
                    dict: 0,
                    word: 'SomeLongWord',
                },
            ],
        },
        {
            id: 1,
            timestamp: '2023-01-26T06:01:30.124Z',
            flags: 2147483647,
            name: 'Second dictionary',
            items: [
                {
                    id: 3,
                    timestamp: '2023-01-26T06:01:30.124Z',
                    dict: 1,
                    word: 'Word',
                },
                {
                    id: 4,
                    timestamp: '2023-01-26T06:01:30.124Z',
                    dict: 1,
                    word: 'Word',
                },
            ],
        },
        {
            id: 2,
            timestamp: '2023-01-26T06:01:30.124Z',
            flags: 2147483647,
            name: 'Just dict',
            items: [
                {
                    id: 5,
                    timestamp: '2023-01-26T06:01:30.124Z',
                    dict: 2,
                    word: 'Word',
                },
            ],
        },
    ],
} as DictionariesPayload;
