import { Dispatch, RefObject, SetStateAction, useRef, useState, useEffect, useCallback } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeleteResultMutation, useLazyDownloadTranscriptFileQuery } from 'api/routes/ApiApi';
import { useLazyDownloadFileByIdQuery, useGetFilesQuery } from 'api/routes/RecordsApi';
import { ReactComponent as ArrowIcon } from 'assets/arrow-without-body.svg';
// import { ReactComponent as CleanIcon } from 'assets/transcript/clean.svg';
import { ReactComponent as CalendarIcon } from 'assets/transcript/date.svg';
import { ReactComponent as DecreaseFont } from 'assets/transcript/decrease-font.svg';
import { ReactComponent as DownloadIcon } from 'assets/transcript/download.svg';
import { ReactComponent as IncreaseFont } from 'assets/transcript/increase-font.svg';
// import { ReactComponent as InexactIcon } from 'assets/transcript/inexact.svg';
import { ReactComponent as ProtocolIcon } from 'assets/transcript/protocol-list.svg';
import { ReactComponent as RedoIcon } from 'assets/transcript/redo.svg';
import { ReactComponent as UndoIcon } from 'assets/transcript/undo.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-can.svg';
import { ModalWithDimming } from 'components/generic/modal/Modal';
import SearchBar from 'components/generic/searchBar/SearchBar';
import SimpleHeader from 'components/pages/records/header/SimpleHeader';
import TranscriptPlayer from 'components/views/transcriptionsList/transcriptPlayer/TranscriptPlayer';
import { getDateFromTimestamp, getTimeFromTimestamp } from 'helpers/TimeFormatting';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useOutsideClick from 'hooks/useOutsideClick';
import { audioReset } from 'store/reducers/transcriptions/audioSlice';
import { decreaseFontSize, increaseFontSize } from 'store/reducers/transcriptions/transcriptAppearanceSlice';
import {
    resetSearchWords,
    searchWords,
    setNextVersion,
    setPreviousVersion,
} from 'store/reducers/transcriptions/transcriptSlice';
import { reset } from 'store/reducers/transcriptions/transcriptSlice';

import './TranscriptControl.scss';

const RecordControl: React.FC<{ controlRef: RefObject<HTMLDivElement> }> = ({ controlRef }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: files } = useGetFilesQuery();
    const [downloadRecord] = useLazyDownloadFileByIdQuery();
    const [deleteTranscript] = useDeleteResultMutation();
    const [downloadFile] = useLazyDownloadTranscriptFileQuery();

    const { archiveFilterWord: filterWord } = useAppSelector((state) => state.transcript);
    const { editedTranscript, currentVersion, versionChanges, isSaved, transcript, isFiltered, isEmptyFilterResult } =
        useAppSelector((state) => state.transcript);
    const currentRecord = transcript;

    const dropdownRef = useRef<HTMLDivElement>(null);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [searchText, setSearchText] = useState(filterWord || '');
    const isMounted = useRef<boolean>(false);

    useOutsideClick([dropdownRef], () => {
        setDropdownIsOpen(false);
    });

    useEffect(() => {
        if (editedTranscript.result?.length && filterWord) {
            dispatch(searchWords(filterWord));
        }
    }, [dispatch, editedTranscript.result?.length, filterWord]);

    useEffect(() => {
        if (isMounted.current) {
            if (!searchText.length) {
                dispatch(resetSearchWords());
                return;
            }
            const delayDebounceFn = setTimeout(() => {
                dispatch(searchWords(searchText));
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        } else {
            isMounted.current = true;
        }
    }, [dispatch, searchText]);

    const handleSearch = useCallback(
        (text: string) => {
            if (!searchText.length) {
                dispatch(resetSearchWords());
            } else {
                dispatch(searchWords(text));
            }
        },
        [dispatch, searchText],
    );

    const renderModalBody = (setModalIsOpen: Dispatch<SetStateAction<boolean>>, session_id: string) => (
        <React.Fragment>
            <p className='text_light'>Вы действительно хотите удалить запись?</p>
            <p className='text_light'>Отменить это действие будет невозможно.</p>
            <div className='modal_actions'>
                <button type='button' className='cancel_button btn btn_light' onClick={() => setModalIsOpen(false)}>
                    Отменить
                </button>
                <button
                    type='button'
                    className='confirm_button btn btn_danger'
                    onClick={() => {
                        setModalIsOpen(false);
                        deleteTranscript(session_id);
                        navigate('/archive');
                    }}
                >
                    Да, удалить
                </button>
            </div>
        </React.Fragment>
    );

    const getAudioUrl = () => {
        const url = files?.files.find((file) => file.file_id === currentRecord?.file_id)?.stream_url;
        return url || '';
    };

    const getAudioDuration = () => {
        const duration = files?.files.find((file) => file.file_id === currentRecord?.file_id)?.duration;
        return duration || 0;
    };

    return (
        <div className='page_control' ref={controlRef}>
            {currentRecord && (
                <>
                    <SimpleHeader />
                    <div className='transcript_control'>
                        <div className='top_section'>
                            {currentRecord.timestamp && (
                                <div className='date_info'>
                                    <div className='recording_date'>
                                        <CalendarIcon />
                                        <span>{getDateFromTimestamp(currentRecord.timestamp)}</span>
                                    </div>
                                    <div className='upload_date'>
                                        {`Загружено ${getDateFromTimestamp(
                                            currentRecord.timestamp,
                                        )} в ${getTimeFromTimestamp(currentRecord.timestamp)}`}
                                    </div>
                                </div>
                            )}
                            <div className='simple_navigation'>
                                <div className='icon'>
                                    <div
                                        onClick={() => {
                                            dispatch(reset());
                                            dispatch(audioReset());
                                            navigate('/archive');
                                        }}
                                    >
                                        <ArrowIcon />
                                    </div>
                                </div>
                                <div className='title'>
                                    {editedTranscript?.user_path &&
                                        editedTranscript?.user_path[editedTranscript?.user_path.length - 1]}
                                </div>
                            </div>
                            <TranscriptPlayer audioUrl={getAudioUrl()} duration={getAudioDuration()} />
                            <div className='record_actions'>
                                <div
                                    ref={dropdownRef}
                                    className='download_record_wrapper'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDropdownIsOpen(!dropdownIsOpen);
                                    }}
                                >
                                    <div className='download_record btn btn_with_icon btn_secondary'>
                                        <DownloadIcon />
                                    </div>
                                    <ul className={`dropdown_menu ${dropdownIsOpen && 'dropdown_menu--open'}`}>
                                        <li
                                            className='dropdown_menu__item'
                                            onClick={() =>
                                                downloadFile({
                                                    req_id: currentRecord.session_id,
                                                    doc_format: 'docx',
                                                    name: currentRecord.user_path[currentRecord.user_path.length - 1],
                                                })
                                            }
                                        >
                                            Текст docx
                                        </li>
                                        <li
                                            className='dropdown_menu__item'
                                            onClick={() => downloadRecord(currentRecord.file_id)}
                                        >
                                            Аудио
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    className='delete_transcript btn btn_with_icon btn_warning'
                                    onClick={(e) => {
                                        setModalIsOpen(!modalIsOpen);
                                    }}
                                >
                                    <TrashIcon />
                                </div>
                            </div>
                        </div>
                        <div className='bottom_section'>
                            <div className='actions_group'>
                                <div
                                    className={`undo_action btn btn_with_icon ${
                                        currentVersion === 0 && 'btn-disabled'
                                    }`}
                                    onClick={() => {
                                        dispatch(setPreviousVersion());
                                    }}
                                >
                                    <UndoIcon />
                                </div>
                                <div
                                    className={`redo_action btn btn_with_icon ${
                                        currentVersion === versionChanges.length - 1 && 'btn-disabled'
                                    }`}
                                    onClick={() => {
                                        dispatch(setNextVersion());
                                    }}
                                >
                                    <RedoIcon />
                                </div>
                            </div>
                            <div className='actions_group'>
                                <div
                                    className='increase_font btn btn_with_icon'
                                    onClick={() => {
                                        dispatch(increaseFontSize());
                                    }}
                                >
                                    <IncreaseFont />
                                </div>
                                <div
                                    className='decrease_font btn btn_with_icon'
                                    onClick={() => {
                                        dispatch(decreaseFontSize());
                                    }}
                                >
                                    <DecreaseFont />
                                </div>
                            </div>
                            {/* закомментировано до появления соответствующего функционала */}
                            {/* <div className='actions_group'>
                                <div className='btn btn_with_icon'>
                                    <InexactIcon />
                                </div>
                                <div className='clean btn btn_with_icon'>
                                    <CleanIcon />
                                </div>
                            </div> */}
                            {currentRecord.timestamp && (
                                <div className='saving_date'>
                                    {/* доработать */}
                                    {isSaved
                                        ? `Сохранено ${getDateFromTimestamp(
                                              currentRecord.timestamp,
                                          )} в ${getTimeFromTimestamp(currentRecord.timestamp)}`
                                        : 'Saving...'}
                                </div>
                            )}
                            <div
                                style={{ flexGrow: '1' }}
                                className={`${isFiltered && isEmptyFilterResult ? 'not_found' : ''}`}
                            >
                                <SearchBar
                                    defaultText='Искать в записи'
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    onPressEnter={handleSearch}
                                />
                            </div>
                            <div className='protocol_btn btn btn_with_icon'>
                                <ProtocolIcon />
                                <span>Протокол</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {modalIsOpen && (
                <ModalWithDimming
                    hideModal={() => setModalIsOpen(false)}
                    modalBody={renderModalBody(setModalIsOpen, currentRecord?.session_id || '')}
                    modalTitle='Удаление'
                />
            )}
        </div>
    );
};

export default RecordControl;
